import React from 'react';
import {withRouter} from 'react-router-dom';

import Header from './Header';
import Main from './../../containers/Main';
import Footer from './Footer';
import './Layout.css';

import bgHome from './bg-home.png';
import bgRecipes from './bg-recipes.png';
import bgFavorites from './bg-favorites.png';
import bgPantry from './bg-pantry.png';

const Layout = (props)=> {

	// bg per route
	let bgUrl =  bgHome;
	let wrapperClass = 'wrapper-home';
	const currentPage = props.location.pathname.split("/").slice(1,2);
	
	switch(currentPage.toString()){
		case 'recipe'		: wrapperClass = 'wrapper-recipes';bgUrl = bgRecipes; break;
		case 'favorites'	: wrapperClass = 'wrapper-favorites';bgUrl = bgFavorites; break;
		case 'pantry'		: wrapperClass = 'wrapper-pantry';bgUrl = bgPantry; break;
		default 			: wrapperClass = 'wrapper-home';bgUrl = bgHome;
	}

	// STICKY HEADER
	const [isSticky, setIsSticky] = React.useState('');
	const stickyHeaderHandler = event => {
		const currTop = window.scrollY;
		// buffer
		if(currTop > 0 && !isSticky){
			setIsSticky('isSticky');
	    	console.log('top', currTop);
		}
		if(currTop === 0 && isSticky){
			setIsSticky('');
	    	console.log('top', currTop);
		}
	}

	// add scroll lister
	React.useEffect(()=>{
		window.addEventListener('scroll', stickyHeaderHandler, true);
		// cleanup
		return ()=>window.removeEventListener('scroll', stickyHeaderHandler);	
	});


	return (
		<div 	className={`wrapper ${wrapperClass} ${isSticky}`} 
				style={{backgroundImage:`url(${bgUrl})`}} 
			>
			<Header />
			<Main />
			<Footer />
		</div>
	);
};

export default withRouter(Layout);