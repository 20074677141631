import React from 'react';

import { FaAngleLeft, FaSlidersH } from 'react-icons/fa';
import classes from './Filters.module.css';
import FilterGroup from './FilterGroup.js';

// must be a fn to avoid jest testing error
const isTabletOrHigher =  ()=> window.matchMedia("(min-width: 768px)").matches;

const Filters = (props)=> {

	// FILTER LIST
	const filterList = [
		{ 	type:"checkbox", 	
			id:"type", 		
			label:"Meal Type", 	
			values:[
					'breakfast',
					'lunch',
					'dinner',
					'appetizer',
					'dessert',
					'beverage',
					'snack',
					// 'main course',
					// 'side dish',
					// 'soup',
					// 'salad',
					// 'sauce',
					// 'bread',
					// 'marinade',
					// 'fingerfood',
					// 'drink'
				]
		},
		{ 	type:"checkbox", 	
			id:"cuisine", 	
			label:"Cuisine", 	
			values:[
					'American',
					'Mexican',
					
					'Italian',
					
					'Indian',
					'Chinese',
					'Japanese',
					'Thai',
					// 'Korean',
					// 'Vietnamese',
					
					'Asian',
					'European',
					'Mediterranean',
					
					// 'African',
					// 'Greek',
					// 'British',
					// 'Cajun',
					// 'Caribbean',
					// 'Eastern', 
					// 'French',
					// 'German',
					// 'Irish',
					// 'Jewish',
					// 'Latin American',
					// 'Middle Eastern',
					// 'Nordic',
					// 'Southern',
					// 'Spanish',
					]
		},
		{ 	type:"checkbox", 	
			id:"diet", 		
			label:"Diet", 		
			values:[
					"vegetarian",
					"vegan",
					"white meat",
					"very healthy",
					"pescetarian", 
					"weight watch",
				   ]
		},
		{ 	type:"checkbox", 	
			id:"intolerances", 	
			label:"Intolerances", 	
			values:[
					"dairy",
					"egg",
					"gluten",
					"grain",
					"peanut",
					"seafood",
					"sesame",
					"shellfish",
					"soy",
					"wheat"
					// "sulfite",
					// "tree nut",
					]
		},
	]
	
	// SHOW/HIDE
	const [showFilter, setShowFilter] = React.useState(isTabletOrHigher());     
	
	// FILTER GROUPS
	const filterGroups = filterList.map(group=>{
		return <FilterGroup 
					type={group.type}
					key={group.id}
					id={group.id}
					label={group.label}
					values={group.values}
					onChangeHandler={props.onChangeHandler}
					resultLoading={props.resultLoading}
					searchData={props.searchData}
				/>;
	});

	return (
		<aside className={`${classes.Aside} ${ showFilter? classes.FilterShow : classes.FilterHide }`}>
                    
            { (showFilter) 
                ? <FaAngleLeft 	className={`${classes.ToggleBtn} ${classes.ToggleBtnShow}`} 
                				onClick={ ()=>setShowFilter(!showFilter) } />
                : <FaSlidersH 	className={`${classes.ToggleBtn} ${classes.ToggleBtnHide}`} 
                				onClick={ ()=>setShowFilter(!showFilter) } />
            }
			
			<form 	ref={props.filterForm} 
					className={`${classes.Wrapper}`} 
				>
				<div className={classes.GroupWrapper} >
					{filterGroups}
				</div>
			</form>

		</aside>
	);
};

export default Filters;