import React from 'react';

import './Home.css';
import SearchForm from './../components/Search/SearchForm/SearchForm';
import QuickSearch from './../components/QuickSearch/QuickSearch';
import PageTitle from './../components/UI/PageTitle';

const Home = (props)=> {
	
	return (
		<section ref={props.homeRef} className={`home ${props.aniClasses}`}>
			<PageTitle><span>Recipes</span> that Match your Pantry</PageTitle>
			<SearchForm />
			<QuickSearch />
		</section>
	);
};

export default Home;