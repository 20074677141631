import React from 'react';
import {useIsImageLoaded} from './../../Hooks/Hooks';

import AddToFav from './../Favorites/AddToFav';
import spinner from './../UI/spinner.svg';

const Comp = (props)=> {
	// check if recipe thumbnail loaded
	// otherwise show spinner
	const isLoaded = useIsImageLoaded(props.image);
	
	return (
		<div 	className={`cardImg ${props.classes}`} 
				style={ {backgroundImage:`url(${isLoaded ? props.image : spinner})`}}
			>
			<AddToFav recipeId={props.id}/>
		</div>
	);
};

export default Comp;