import React from 'react';

const Comp = (props)=> {

	return (
		<h1 	className={`page-title ${props.classes}`}
				data-testid="page-title"
			>
			{props.children}
		</h1>
	);
};

export default Comp;