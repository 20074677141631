import React from 'react';
import {useHistory} from 'react-router-dom';

import classes from './Card.module.css';
import CardImg from './CardImg';
import CardTitle from './CardTitle';
import ListTags from './../ListTags/ListTags';
import ListFacts from './../ListFacts/ListFacts';

const Comp = (props)=> {
	
	const history = useHistory();
	const showSingleRecipeHandler = ()=>{
		history.replace(`${history.location.pathname}/${props.id}`);
	}

	return (
		<div 	id={props.id} 
				className={`card ${classes.Card}`}
				onClick={showSingleRecipeHandler}
			>
			<CardImg 	image={props.image} 
						id={props.id} 
					/>
			<div className="cardContent" >
				<CardTitle 	title={props.title} />
				<ListTags ingTagList={props.ingTagList} cardId={props.id} />
				<ListFacts factsList={props.factsList} />
			</div>
		</div>
	);
};

export default Comp;