import React from 'react';

import classes from './PantryIng.module.css';
import Tooltip from './../UI/Tooltip';

const PantryPredefinedIngList = (props)=> {
	// required for Tooltip
	// to get current top and left of parent wrapper
	const refWrapper = React.useRef();
	
	// activate tooltip
	const [showTooltip, setShowTooltip] = React.useState(false);
	const showTooltipHandler = (event, status) => {
		setTimeout(()=>{setShowTooltip(status)},100);
	}

	return (
		<div 	className={`tooltip ${classes.PredefinedItem} ${props.isSelected && classes.CurrentSelected}`}
				ref={refWrapper}
				onClick={props.addPredefinedIngHandler}
				onMouseOver={(event)=>showTooltipHandler(event,true)}
				onMouseLeave={(event)=>showTooltipHandler(event,false)}
			>
			{props.icon}
			<h3>{props.label}</h3>
			<Tooltip display={showTooltip} refWrapper={refWrapper} tooltipText={props.summary} />
		</div>
	);

};

export default PantryPredefinedIngList;