import * as actionTypes from './../actions/actionTypes';

export const authInitialState = {
						authLoading: false,
						authData: null,
						authError:null,
						authRedirect: null,
						authResetPasswordSuccess: null,

						authUserPantry: [
				            {id:11215, label:"garlic", status: true},
				            {id:1001, label:"butter", status: true},
				            {id:11216, label:"ginger", status: true},
				            {id:2047, label:"salt", status: true},
				            {id:11011, label:"asparagus spears", status: true},
				            {id:4053, label:"olive oil", status: true},
				        ],
						authUserFavorite:[
				            {id:918291, title:"Chewy Almond Flour Sugar Cookies"},
				        ],
					};

const authReducer = (state = authInitialState, action)=>{

	switch(action.type){
		case actionTypes.AUTH_START: return{ ...state, ...action}; 
		case actionTypes.AUTH_SUCCESS: return{ ...state, ...action}; 
		case actionTypes.AUTH_SUCCESS_RESETPASSWORD: return{ ...state, ...action}; 
		case actionTypes.AUTH_FAIL: return{ ...state, ...action}; 
		case actionTypes.AUTH_SETREDIRECT: return{ ...state, ...action}; 
		case actionTypes.AUTH_LOGOUT: return{ ...state, ...action}; 
		case actionTypes.RESET: return{ ...state, ...action};
		
		default: return state;
	}

};

export default authReducer;