import { combineReducers } from 'redux'

import authReducer from './authReducer';
import searchReducer from './searchReducer';
import pantryReducer from './pantryReducer';

const Reducers = combineReducers({ 
						authReducer: authReducer,
						searchReducer: searchReducer, 
						pantryReducer: pantryReducer, 
						favoriteReducer: pantryReducer, 
				});

export default Reducers;