import React from 'react';
import axios from 'axios';

import {connect} from 'react-redux';
import * as actions from './../../store/actions/actions';

import classes from './Featured.module.css';

const Featured = (props)=> {

	const saveFeatured = JSON.parse(sessionStorage.getItem('featuredRecipe'));
	const [randomRecipe, setRandomRecipe] = React.useState(saveFeatured);
	
	// FEATCH RANDOM RECIPE
	// use different api endpoint
	// we only update title and summary info here
	// temp data and will not update store
	// only show single recipe per session - saved to session 
	// onclick event redirects to standard search
	React.useEffect(()=>{
		// setup request headers
		//let key  =process.env.REACT_APP_SPC_API;
		//let spcurl  = process.env.REACT_APP_SPC_API_URL;
        //let url  = spcurl+'/recipes/random?number=1&apiKey='+key;
        // request
        // wait until after intro is shown to memory-leak complain
        if(!saveFeatured){
			const gcfUrl      = process.env.REACT_APP_GCF_URL;
            const gcfEndpoint = 'randomrecipe'; 
            const param       = ''; 
            axios.get(gcfUrl+gcfEndpoint+param)
            .then(response=>{
				setRandomRecipe(response.data.recipes[0]); 
				// save to session
				sessionStorage.setItem('featuredRecipe', JSON.stringify(response.data.recipes[0]));
			})
			.catch(error=>{
				console.log('**random error:');
			});
        }
	},[saveFeatured]);

	// edit response data
    // be sure to remove any ','s in string
    // lowercase to make by-name search compatible
    // api will NOT match 'query' as matchTitle param 
	let titleMatchQuery = randomRecipe ? randomRecipe.title.replace(/,/g, '').toLowerCase() : null;
	// remove html and truncate summary and title
	let plainSummary = randomRecipe ? randomRecipe.summary.replace(/<[^>]*>/g, '').replace(/^(.{100}[^\s]*).*/, "$1...")  : '';
	let featuredTitle = randomRecipe ? randomRecipe.title.replace(/^(.{36}[^\s]*).*/, "$1...") : '';

	return (
		<React.Fragment>
			{ 
				randomRecipe
					?(
						<div 	className={classes.Wrapper} 
								onClick={()=>props.searchSubmitHandler('/recipe', {titleMatch:titleMatchQuery}, true) }
							>
							<h2 title={randomRecipe.title}><span>Featured</span> {featuredTitle} </h2>
							<p>{plainSummary}</p>
						</div>
					)
					// error
					:(
						<div 	className={classes.Wrapper} >
							<h2 ><span>Featured</span></h2>
							<p>No Data Avaialble</p>
						</div>
					)
			}
		</React.Fragment>
	);
};

const mapDispatchToProps = dispatch =>{
	return {
		searchSubmitHandler: (redirectLink, searchQuery, saveQuery) => dispatch( actions.search_submit(redirectLink, searchQuery, saveQuery) ),
	}
}

export default connect(null, mapDispatchToProps)(Featured);