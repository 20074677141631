import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route, useHistory} from 'react-router-dom';
import { Transition } from 'react-transition-group';

import * as actions from './../store/actions/actions';

import './Main.css';
import Auth from './Auth';
import Home from './Home';
import Recipes from './Recipes';
import Pantry from './Pantry';
import Favorites from './Favorites';

const Main = (props)=> {

	const homeRef = React.useRef();
	const recipeRef = React.useRef();
	const history = useHistory();
	const {authRedirect, searchRedirect, storeResetHandler} = props;
	const [pageRedirect, setPageRedirect] = React.useState('');
	
	
	/*
	* MANAGE REDIRECTS
	* searchResults redirect
	* authRedirects
	*/
		// APPLY SEARCH REDIRECT
		// manage show/hide results ani
		React.useEffect(()=>{
			if(searchRedirect){
				// redirect
				setPageRedirect(searchRedirect);
				// cleanup
				// reset search-redirect
				return ()=> storeResetHandler('searchRedirect');
			}
		},[searchRedirect, storeResetHandler, pageRedirect]);
		
		// APPLY AUTH REDIRECTS
		// manage logout redirect
		// auth redirects
		// const {authRedirect, storeResetHandler} = props;
		React.useEffect(()=>{
			if( authRedirect ){
				// set redirect
				setPageRedirect(authRedirect);
				// cleanup
				// reset auth-redirect
				return ()=> storeResetHandler('authRedirect'); 
			}
		},[authRedirect, storeResetHandler, pageRedirect]);

		// DO REDIRECTS
		React.useEffect(()=>{
			if(pageRedirect){
				// clear any params
				history.location.search = null;
				// redirect
				history.replace(pageRedirect);
				setPageRedirect(''); 			// reset redirect
			}
		},[pageRedirect, history]);

	return (
		<main>
		    {
		    	// handles all auth related routes
		    	// we use modal and still render other-pages underneath
		    	// prompts login-prompt, login, signup, re-auth 
		    	// only route if user NOT authenticated or has authErrors
		    	// TODO: convert to privateroute <AuthRoute path='/auth' component={Auth} />
		    	(!props.authData || props.authError) && <Auth />
		    }
			<Switch>
				<Route path='/favorites' component={Favorites} />
				<Route path='/pantry' component={Pantry} />
				<Route path='/' render={(props)=>{
						// render both search and results
						// @ / path to apply smooth transition @ mount/unmount
						return (
							<React.Fragment>
								<Transition 
									  in={props.match.isExact}	// show/hide component 
									  timeout={{enter:100, exit:200}} 	// indiv
									  unmountOnExit={true}
									  nodeRef={homeRef} 
									  //onExited={()=> setPageRedirect('')}
								>
									{	state => <Home homeRef={homeRef} aniClasses={`search search-${state} home`} /> 	}
								</Transition>
								<Transition 
									  in={history.location.pathname.includes('/recipe')}	// show/hide component 
									  timeout={{enter:100, exit:100}} 	// indiv
									  unmountOnExit={true}
									  nodeRef={recipeRef} 
									  //onExited={()=> setPageRedirect('')} 
								>
									{	
										state => (
										    <Recipes 	{...props}
										   				recipeRef={recipeRef} 
										   				aniClasses={`searchresults searchresults-${state}`} 
										   			/>
										)
									}
								</Transition>
							</React.Fragment>
						)
					}} />
			</Switch>
		</main>
	);

};

const mapStateToProps = state => {
	return {
		authData: state.authReducer.authData,
		authError: state.authReducer.authError,
        authRedirect: state.authReducer.authRedirect,

        //searchData: state.searchReducer.searchData,
        searchRedirect: state.searchReducer.searchRedirect,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		//authSubmitHandler: (values, loginType,redirectLink) => dispatch( actions.auth_submit(values,loginType,redirectLink) ),
		storeResetHandler: (resetProp) => dispatch( actions.reset(resetProp) ),
		
		//pantryFetchHandler: (user) => dispatch( actions.pantry_fetch(user) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);