import * as actionTypes from './actionTypes.js';
import axios from 'axios';

// start
export const pantry_start = (updateType="fetch")=>{
	return{
		type: actionTypes.PANTRY_START,
		pantryLoading: updateType === "fetch" ? true : false,
		pantryLoadingUpdate: updateType === "update" ? true : false,
		pantryLoadingMatch: updateType === "match" ? true : false,
		pantryError: null,
		pantryErrorUpdate: null,
		pantryErrorMatch: null,
	}
}

// success
export const pantry_success = (data)=>{
	return{
		type: actionTypes.PANTRY_SUCCESS,
		pantryData: data,
		pantryLoading: false,
		pantryLoadingUpdate: false,
		pantryLoadingMatch: false,
		
		pantryError: null,
		pantryErrorUpdate: null,
		pantryErrorMatch: null,
	}
}

// fail
export const pantry_fail = (error, updateType="fetch")=>{
	return{
		type: actionTypes.PANTRY_FAIL,
		pantryLoading: false,
		pantryLoadingUpdate: false,
		pantryLoadingMatch: false,
		
		pantryError: updateType === "fetch" ? error : null,
		pantryErrorUpdate: updateType === "update" ? error : null,
		pantryErrorMatch: updateType === "match" ? error : null,
		
		//pantryData: null,	// not using to preserve fetch data if update error
	}
}

	/*
	* HELPER ACTIONS
	*/

	// MARK action type // fetch, update, match  
	export const pantry_actiontype = (type)=>{
		return{
			type: actionTypes.PANTRY_ACTIONTYPE,
			pantryActionType: type,
		}
	}

	// reset
	export const reset = (resetProp)=>{
		return{
			type: actionTypes.RESET,
			[resetProp]: null,
		}
	}


// async FB - fetch pantry items
export const pantry_fetch = (user, action='get')=>{
	return (dispacth, getState) => {
		// start
		dispacth( pantry_start('fetch') );
		// mark actiontype
		dispacth( pantry_actiontype('fetch') );
		
		// setup request headers
		// let key  =process.env.REACT_APP_FB_API;
		let userObj = getState().authReducer.authData;
		let idToken = userObj && userObj.idToken;
		let fburl   = process.env.REACT_APP_FB_API_URL;
		let url     = fburl+'/pantry/'+user+'.json?auth='+idToken;
		
		// request
		axios.get(url)
		.then(response=>{
			dispacth( pantry_success(response.data, 'fetch') );
		})
		.catch(error=>{
			console.log('**pantry fetch error:');
			dispacth( pantry_fail(error.response.data.error, 'fetch') );
		});
		
	}
}

// async FB - update pantry items
export const pantry_update = (user, data, action='patch')=>{
	return (dispacth, getState) => {
		// start
		dispacth( pantry_start('update') );
		// mark actiontype
		dispacth( pantry_actiontype('update') );
		
		// setup request headers
		let fburl   = process.env.REACT_APP_FB_API_URL;
		let userObj = getState().authReducer.authData;
		let idToken = userObj && userObj.idToken;
		let url     = fburl+'/pantry/'+user+'.json?auth='+idToken;

		// put - defined key
		let body = data;

		// request
		let resultsPromise;
		switch(action){
			case 'delete' 	: 	// adjust url
								url  = fburl+'/pantry/'+user+'/'+data+'.json?auth='+idToken;
								resultsPromise = axios.delete(url); break;
			case 'put' 		: 	resultsPromise = axios.put(url, body); break;
			// default is patch
			default 		: 	resultsPromise = axios.patch(url, body); break;
		}
		
		resultsPromise.then(response=>{
			// trigger re-fetch to get updated pantryData
			dispacth( pantry_fetch(user) );
		})
		.catch(error=>{
			console.log('**pantry update error:');
			dispacth( pantry_fail(error.response.data.error, 'update') );
		});
		
	}
}

// ASYNC SP match ings to query
// MATCH INGS IN THE QUERY and UPDATE PANTRY
export const pantry_matchIngsToQuery = (query, action)=>{
	return (dispacth, getState) => {
		// start
		dispacth( pantry_start('match') );
		// mark actiontype
		dispacth( pantry_actiontype('match') );

		// match and update
        // match query to ings with SP
        // update pantry list with FB
		const gcfUrl      = process.env.REACT_APP_GCF_URL;
        const gcfEndpoint = 'matchingredients'; 
        const param       = "?inglist="+encodeURI(query.join('\n')); 	// .join(\n) is api param preference new-line; 

        // request
        axios.post(gcfUrl+gcfEndpoint+param)
        .then(response=>{
            // setup update params
            let pantryUpdateBody = {};
            response.data.forEach( ingData => {
                // skip onec w/o aisle or label
                if( !ingData.name || !ingData.aisle){
                    return;
                }
                // setup param
                pantryUpdateBody[ingData.id] = {
                                            "id": ingData.id,
                                            "label": ingData.name,
                                            // reformat aisle. sometimes data comes as ; separation
                                            // standardized to , separated string
                                            "aisle": (ingData.aisle) ? ingData.aisle.replace(/;/g, ', ') : null,
                                            "cart": false
                                        } 
            })

            // setup update type with user
            dispacth( pantry_update( getState().authReducer.authData.localId, pantryUpdateBody, (action === 'replace') ? 'put' : 'patch') );
        })
        .catch(error=>{
            console.log('**matchIng error:', error.response);
            // match to gcf errors 
            dispacth( pantry_fail(error.response ? error.response.data.error : error, 'match') );
        });
		
	}
}