import React from 'react';

import classes from './QuickSearch.module.css';

const QuickSearchItem = (props)=> {

	return (
		<div 	className={classes.Item} 
				onClick={props.searchSubmitHandler}
			>
			{props.icon}
			<p>{props.title}</p>
		</div>
	);
};

export default QuickSearchItem;