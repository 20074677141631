import React from 'react';
import {NavLink} from 'react-router-dom';

const Comp = (props)=> {

	return (
		<NavLink 	to={props.link} 
					onClick={ props.action } 
				>
			{props.children}
		</NavLink>
	);
};

export default Comp;