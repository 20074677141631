import React from 'react';

// GET PREVIOUS STATE Hook
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef();
  
  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// TRACK MOUSE POSITION
export function useMousePosition(){
  const [mousePosition, setMousePosition] = React.useState({ x: null, y: null });

  const updateMousePosition = ev => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  React.useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  },[]);

  return mousePosition;
};

// IS IMAGE LOADED
export function useIsImageLoaded(src){  
  const [sourceLoaded, setSourceLoaded] = React.useState(null)

  React.useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  },[src]);

  return sourceLoaded 
}