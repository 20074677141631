import React from 'react';

import classes from './Login.module.css';
import Button from './../UI/Button';
import Input from './../UI/Input';
import Alert from './../Alert/Alert';


const LoginForm = (props)=>{

	return (
		<form onSubmit={props.handleSubmit} autoComplete="off" data-testid='login-form'>
			<p>Enter a new password and click Reset Password button to reset your password.</p>
			{ (props.authError) && <Alert  message={props.authError.message} type="error" reset="authError" /> }
			<Input 	name="new_password"
					id="new_password"
					label="New Password"
					labelClass="sr-only"
					type="password"
					inputClass={props.errors.new_password && props.touched.new_password?'has-error':''}
					placeholder="New Password"
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.new_password}
					errorText={props.errors.new_password && props.touched.new_password ? props.errors.new_password : null }
				/>
			<Input 	name="confirm_password"
					id="confirm_password"
					label="Confirm Password"
					labelClass="sr-only"
					type="password"
					inputClass={props.errors.confirm_password && props.touched.confirm_password?'has-error':''}
					placeholder="Confirm Password"
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.confirm_password}
					errorText={props.errors.confirm_password && props.touched.confirm_password ? props.errors.confirm_password : null }
				/>
			<Input 	name="oobcode"
					testid="oobcode"
					type="hidden"
					value={props.values.oobcode}
				/>
			<div 	className={classes.BtnWrapper}
					style={{textAlign:"center", marginTop:"2em"}} >
				<Button type="submit"
						text="Reset Password"
					/>
			</div>
		</form>
	);
};

export default LoginForm;