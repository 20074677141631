import React from 'react';
import Logo from './../UI/Logo';
import MainMenu from './../Menu/MainMenu/MainMenu';

const Comp = (props)=> {
	return (
		<header >
			<div>
				<Logo />
				<MainMenu />
			</div>
		</header>
	);
};

export default Comp;