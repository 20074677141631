import React from 'react';
import {connect} from 'react-redux';
import * as actions from './../../../store/actions/actions';

import IngJson from './../../../matchandcook_ingredients.json';

import classes from './SearchForm.module.css';
import { FaSearch, FaCircleNotch  } from 'react-icons/fa';
import Input from './../../UI/Input';

import {Chip, InputAdornment} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';


const SearchForm = (props, state)=> {

// INITIALS
// initial selected searchType
// base on current store data
	const [initialSearchQuery, setInitialSearchQuery] = React.useState([]);	// must be []
	const [checkedRadio, setCheckedRadio] = React.useState();
	
	const {searchQuery, authData} = props;
	React.useEffect(()=>{
		if('query' in searchQuery && searchQuery.query ){
			setCheckedRadio('query');
			setInitialSearchQuery( searchQuery.query.split(',') );
		}
		else if('includeIngredients' in searchQuery && searchQuery.includeIngredients ){
			setCheckedRadio('includeIngredients');
			setInitialSearchQuery( searchQuery.includeIngredients.split(',') );
		}
		// we only show byName, byIng options for non-login users
		// and byIng is default for non-login users 
		else if(!authData){
			setCheckedRadio('includeIngredients');
		}
		// so byName is default for login users
		else{
			setCheckedRadio('query');
		}
	},[searchQuery, authData]);

// 01. SEARCH TYPE
// set base on radio selection
// use value - same as api query-params
// search by ingredients 'includeIngredients' (default)
// search by name 'query'

	const radioCheckedHandler = (event) => {
	    setCheckedRadio(event.target.value);
	    event.target.setAttribute('checked',true);
	};
	

// 02. SEARCH SUGGESTIONS
// INGREDIENT NAMES	
// RECIPE NAMES
    // state - suggestion options list
    const [options, setOptions] = React.useState([]);
	// current input text
    const [currentInput, setCurrentInput] = React.useState(null);

    // when user types search text
    // set search query
    const setOptionsHandler = (event, value, reason)=>{
    	// clear existing suggestions
    	setOptions([]);
    	// "byname" search
	    // only after 4 charators
    	if(checkedRadio === 'query'){
	    	if(value.length > 2){
				setCurrentInput(value);
			}
    	}
    	// other search
    	else{
			setCurrentInput(value);
    	}
    }

    // generate suggestion list
    const {searchAutocompleteData, searchAutocompleteLoading, searchAutocompleteError, searchAutocompleteSubmitHandler} = props;
    React.useEffect(()=>{
	    // fetch recipe names
	    // when user input search text
	    // delay 500ms to avoid simultaneous fetch
    	if(currentInput && currentInput.length > 2 && checkedRadio === 'query'){
    		let delayTimer;
    		delayTimer = setTimeout(()=>{
		    	// fetch
		    	searchAutocompleteSubmitHandler(currentInput);
    		},500);
	    	// cleanup timer
	    	return ()=> clearTimeout(delayTimer);
    	}
    	// set ingredients suggestions
    	else{
    		// ingredient list
			const ingOptions = IngJson.map((option) => option.label);
			// set suggestion list
			setOptions(ingOptions);	
    	}
    },[currentInput, checkedRadio, setOptions, searchAutocompleteSubmitHandler ]);

    // generate recipe names list
    // when fetch data available
    React.useEffect(()=>{
    	if(searchAutocompleteData){
    		// create option list
    		const resultArry = searchAutocompleteData.map( (recipe) => {
				return recipe['title'];
			});
			// set suggestion list
			setOptions(resultArry);
    	}
    },[searchAutocompleteData, searchAutocompleteLoading]);

	// new suggestions
	// add user's input as new suggest-options
	const filter = createFilterOptions();
	const filterOptionsHandler = (options, params)=>{	
		const filtered = filter(options, params);
		// generate new value
		// when user start typing
		// if not lading aync values
	    if (params.inputValue !== '' && !searchAutocompleteLoading && !searchAutocompleteError ) {
	      filtered.push(`Add "${params.inputValue}"`);
	    }
	    // set loading text if loading
    	else if(searchAutocompleteLoading){
			// set suggestion list
			filtered.push('Loading...');
    	}
    	// if async load error
    	else if(searchAutocompleteError){
			// set suggestion list
			filtered.push('Error:'+searchAutocompleteError.message);
    	}
    	
	    return filtered;
	}
	
	// generate tags
	// render search term tags
	const renderTagsHandler = (value, getTagProps)=>{
		const values = value.map((option, index) => {
			// remove 'add' from value
			option = option.replace('Add "', '').replace('"', '');
			// create tag
        	return <Chip 	size="small"
		        			label={option} 
		        			{...getTagProps({ index })} 
			        		//onClick={}
			        		//onDelete={}
		        		/>
		});
	  	return values;
	}


// 03. UPDATE SEARCH QUERY
// we use saved searchQuery from store
// during api fetch 
	const [inputQuery, setInputQuery] = React.useState(initialSearchQuery);
	
	// update previous search term
	React.useEffect(()=>{
		if(initialSearchQuery){
			setInputQuery(initialSearchQuery);
		}
	},[initialSearchQuery]);

	// onchange user input
	const  setInputQueryHandler = (event, value, reason)=>{
		// val - search-text
		const queryText = value.map((val) => {
			// remove 'add' from value
			return val.replace('Add "', '').replace('"', '');
		});
		setInputQuery(queryText);
	}


// 04. GENERATE SEARCH TEXT-FIELD
// add search icon
	const renderInputHandler = (params)=>{
		let paramsInputProps = params.InputProps;
		return  (
            <TextField 	{...params}
          				variant="outlined" 
          				label={
          						checkedRadio === 'query'
          						? "Enter Recipe Name"
          						: "Enter Ingredients"
		          			}
          				InputProps={{
								...paramsInputProps,		          
					          	endAdornment: (
					            	<InputAdornment position="end" 
					            					className={`${classes.SearchIcon} iconSearch`} 
					            				>
					              		<button type="submit"
					              				disabled={
					              						// disable search
					              						// if not input || set default values
						              					!(inputQuery.length > 0 || initialSearchQuery )
						              				}
					              				>
					              			{ props.searchLoading ? <FaCircleNotch className="fa-spinner" /> : <FaSearch />}
					            		</button>
					            	</InputAdornment>
					          	),
					        }}
          			/>
        );
	};


// 05. SEARCH SUBMIT
// trigger search fetch
	const searchHandler = (event)=>{
		event.preventDefault();
		// update searchQuery
		const newSearchParam = {};
		const existingSearchParam = { 
										query: null, 
										includeIngredients: null, 
										titleMatch: null,
									} 
		// add searchtype selection
		Object.keys(existingSearchParam).forEach((key)=>{
			newSearchParam[key] = (checkedRadio === key)? inputQuery.join(',') : null;
		});		

		// add minIngSearch
		// see ING SELECTION
		newSearchParam['minIng'] = minIngVal;

		// submit fetch
		if(inputQuery.length > 0){
			props.searchSubmitHandler('/recipe', newSearchParam, true);
		}
	}

/*
*  06. ING SELECTION
*/
	// SLIDER HANDLER
	// we save current selection val to store
	// as searchQuery.minIng for persistent between
	// / and /recipe 
	const [minIngVal, setMinIngVal] = React.useState(searchQuery.minIng ? searchQuery.minIng : 0);
	const ingFilterChangeHandler = (event, value)=>{
		// update state
		setMinIngVal(value);
		// trigger filter
		// only in results page
		if(props.ingFilterHandler){
			props.ingFilterHandler(minIngVal);
		}
	}


return (
    
    <form 	onSubmit={(event)=>searchHandler(event)}
    		id="search-recipe"
    		className={classes.SearchForm}
    	>
	    <Autocomplete
	    	autoHighlight={true}
	    	// autoSelect={true}
	    	multiple={true}
	        id="search"
	        name="search"
	        onChange={(event, value, reason)=>setInputQueryHandler(event, value, reason)}
	        onInputChange={(event, value, reason)=>setOptionsHandler(event, value, reason)}
	        options={options}
	        freeSolo
	        // defaultValue={ inputQuery }
	        loading={props.searchAutocompleteLoading}
	        value={ inputQuery }
	        filterSelectedOptions
	    	filterOptions={(options, params) => filterOptionsHandler(options, params) }
	        disableClearable={true}
	        renderInput={(params) => renderInputHandler(params) }
	        renderTags={(value, getTagProps) => renderTagsHandler(value, getTagProps) }
	    />
		<div className={`${classes.SearchTypeWrapper} ${authData ? classes.PantryMatch : classes.NonPantryMatch}`}>
			{ !props.authData && (
				<React.Fragment>
					<Input 	name='searchBy'
							id='searchByIngredients'
							label='by Ingredients'
							labelClass={`label-left ${(checkedRadio === 'includeIngredients') && 'isChecked' }`}
							value='includeIngredients'
							type='radio'
							initialState={checkedRadio === 'includeIngredients'}
							action={(event)=>radioCheckedHandler(event)} 
						/>
				    <Input 	name='searchBy'
							id='searchByRecipeName'
							label='by Recipe Name'
							labelClass={`label-left ${(checkedRadio === 'query') && 'isChecked' }`}
							value='query'
							type='radio'
							initialState={checkedRadio === 'query'}
							action={(event)=>radioCheckedHandler(event)}
						/>
				</React.Fragment>
				)
			}
			{ 	props.authData && (
				<React.Fragment>
					<label className='label-left'>Pantry Match</label>
	                <Slider
	                    name='minIng'
	                    value={Number(minIngVal)}
	                    step={1}
	                    valueLabelDisplay="auto"
	                    onChange={(event, value) => ingFilterChangeHandler(event, value) }
	                />
	                <span className='label-percentage'>%</span>
				</React.Fragment>
            	)
    		}
	    </div>
    </form>
);


};

const mapStateToProps = state =>{
	return {
		authData: state.authReducer.authData,		
		searchQuery: state.searchReducer.searchQuery,
		searchAutocompleteLoading: state.searchReducer.searchAutocompleteLoading,
		searchAutocompleteData: state.searchReducer.searchAutocompleteData,
		searchAutocompleteError: state.searchReducer.searchAutocompleteError,
		
		searchLoading: state.searchReducer.searchLoading,
	}
}

const mapDispatchToProps = dispatch =>{
	return {
		searchAutocompleteSubmitHandler: (query) => dispatch( actions.search_autocomplete_submit(query) ),
		searchSubmitHandler: (redirectLink, serchQuery, saveQuery) => dispatch( actions.search_submit(redirectLink, serchQuery, saveQuery) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);