import React from 'react';

import classes from './NotFound.module.css';
import searchnotfound from './search-not-found.svg';
import PageTitle from './../UI/PageTitle';

const NotFound = (props)=> {
	// exit if no results
	if(!props.resultLoading && (!props.results || props.results.length < 1)) {
		return(
			<div className={classes.NotFoundWrapper}>
	            <img src={searchnotfound} alt="search not found" />
	            <PageTitle classes={`${classes.Title} ${props.isError && 'error'}`} >
	            	{props.title}
	            </PageTitle>
	            <h4 className={classes.SubTitle} >
	            	{props.subTitle} 
	            </h4>
	            <div className={classes.Suggestion} >
	            	{props.children}	
	            </div>
	        </div>
		);
	}	
};

export default NotFound;