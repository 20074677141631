import React from 'react';
import FooterMenu from './../Menu/FooterMenu/FooterMenu';

const Comp = (props)=> {

	return (
		<footer>
			<FooterMenu />
			<div className="copyright-text">
				Copyright © 2020 matchandcook.com, Los Angeles.
			</div>
		</footer>
	);
};

export default Comp;