import React from 'react';
import {Link} from 'react-router-dom';

import classes from './Login.module.css';
import Button from './../UI/Button';
import Input from './../UI/Input';
import Alert from './../Alert/Alert';


const LoginForm = (props)=>{

	return (
		<form onSubmit={props.handleSubmit} autoComplete="off" data-testid='login-form'>
			<p>Enter your email address, and we'll send instructions to reset your password.</p>
			{ (props.authError) && <Alert  message={props.authError.message} type="error" reset="authError" /> }
			<Input 	name="user_email"
					id="user_email"
					label="Email"
					labelClass="sr-only"
					type="email"  
					inputClass={props.errors.user_email && props.touched.user_email?'has-error':''}
					placeholder="Your email"
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.user_email}								
					errorText = {props.errors.user_email && props.touched.user_email ? props.errors.user_email : null }
				/>
			<div 	className={classes.BtnWrapper}
					style={{textAlign:"center", marginTop:"2em"}} >
				<Button type="submit"
						text="Send reset link"
					/>
				<Link 	className={classes.SignupLoginLink}
						to={`${props.currentPath}?prompt=login`}
					>Back to Login</Link>
			</div>
		</form>
	);
};

export default LoginForm;