import React from 'react';

import classes from './PantryIng.module.css';
import Input from './../UI/Input';

const PantryIngTableFilter = (props)=> {

	return (
		<div className={classes.Filter}>
			<Input 	name="show_shopping_list"
					id="show_shopping_list"
					label="Shopping List"
					type="checkbox"  
					action={props.shopListFilterHandler} 
					labelClass="label-right"
					// inputClass=""
					// placeholder="E-mail"
				/>
			<Input 	name="ing_search_filter"
					id="ing_search_filter"
					label="Filter by Keyword"
					type="input"  
					action={props.filterSearchHandler} 
					labelClass="sr-only"
					placeholder="Filter by Keyword"
					// inputClass={}
					wrapperClass={classes.IngSearchInputWrap}
				/>
		</div>
	);

};

export default PantryIngTableFilter;