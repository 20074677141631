import React from 'react';
import { FaClock, FaCoffee, FaHamburger, FaUtensils  } from 'react-icons/fa';

import {connect} from 'react-redux';
import * as actions from './../../store/actions/actions';

import classes from './QuickSearch.module.css';
import QuickSearchItem from './QuickSearchItem';

const QuickSearchList = (props)=> {

	const itemList = [
		{icon:<FaClock />, title:'Snacks', term:'snack'},
		{icon:<FaCoffee />, title:'Breakfast', term:'breakfast'},
		{icon:<FaHamburger />, title:'Lunch', term:'lunch'},
		{icon:<FaUtensils />, title:'Dinner', term:'dinner'},
	];

	const list = itemList.map(item=>{
		return (   <QuickSearchItem 	className={classes.Item}
										key={item.title}
										icon={item.icon}
										title={item.title}
										searchSubmitHandler={()=>props.searchSubmitHandler('/recipe', { 
																							query: item.term,
																							includeIngredients:null,
																							titleMatch:null,
																						}, true )}
										term={item.term} 
										/> 
				);
	});

	return (
		<div className={classes.SearchList}>
			{list}
		</div>
	);
};

const mapDispatchToProps = dispatch =>{
	return {
		searchSubmitHandler: (redirectLink, searchQuery, saveSearch) => dispatch( actions.search_submit(redirectLink, searchQuery, saveSearch) ),
	}
}

export default connect(null, mapDispatchToProps)(QuickSearchList);