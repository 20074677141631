import React from 'react';
import {connect} from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { FaCircle  } from 'react-icons/fa';


import * as actions from './../store/actions/actions';
import classes from './Favorites.module.css';
import ListRecipes from './../components/ListRecipes/ListRecipes';
import PageTitle from './../components/UI/PageTitle';
import RecipeSingle from './../components/RecipeSingle/RecipeSingle';
import Loading from './../components/UI/Loading';
import NotFound from './../components/NotFound/NotFound';


const Favorites = (props)=> {

    const {favoriteData} = props;
    
    // prompt IF NOT LOGIN
    const history = useHistory();
    React.useEffect(()=>{
        if(!props.authData){
            history.replace('/?prompt=login')
        }
    },[props.authData, history]);

    /*
    * RESULT LIST
    * we fetch 100 records at a time, display as 10(recipes per page) x 10(pages per pagigroup) 
    * - assign 
    * - we match user's pantry and rearrange results (100)
    * - get saved fetch from session
    * - loop, match pantry, update and sort per max-ings     
    * - break into 10 sets     
    */

        // get saved fetch
        const perPage = 10;
        const initialFetchedData = {
                                        number:perPage,
                                        totalResults:0,
                                        offset:0,
                                        results:[],
                                    };
        const [currentResultSet, setCurrentResultSet ] = React.useState(initialFetchedData);     

        // break recipes into 10sets
        // start == current pagi-page
        // end == start + per page
        // we always save 100 at a time - results:[0:{}(10),1:{}(20),...,100:{}(100)]
        const setCurrentResultSetHandler = React.useCallback((pagiPage, reset)=>{ 
            // so pagi-pagiPage # is always between 0 - 9
            // ---------
            // *****searchPagi offset start @ 0 
            let recipePage = pagiPage-1;    
            // ---------
            // output current 10 recipes from 100
            // const currentPagiGroup = favoriteData;
            const resultsSetStart  = recipePage*perPage;
            const resultsSetEnd    = resultsSetStart+perPage;
            const results          = [];
            let   totalRecipes     = 0;
            // get range data            
            Object.keys(favoriteData).forEach( recipe =>{
                // count after ingFilter
                // use counter as key to match recipe
                totalRecipes++;

                // get n results
                if( totalRecipes > resultsSetStart && totalRecipes <= resultsSetEnd ){
                    results.push(favoriteData[recipe]);
                }
            });

            // update current results
            setCurrentResultSet( {
                pagiPage: pagiPage, // mark current pagi page selection
                results: results,
                reset: reset,
                totalResults: totalRecipes,
            } );
        },[favoriteData]);



    /*
    * WHEN NEW API FETCH
    * set current result set
    */
    const { pagiPage, results } = currentResultSet;
    React.useEffect(()=>{
        if( favoriteData && Object.keys(favoriteData).length ){
            // update currentResultSet
            // default to existing page
            // to retain pagipostition after addToFav
            setCurrentResultSetHandler(pagiPage ? pagiPage : 1 , false);
        }
        else{
            // reset currentResultsSet if not favorites
            // and already not empty
            if(results.length){
                setCurrentResultSet({
                                        number:perPage,
                                        totalResults:0,
                                        offset:0,
                                        results:[],
                                    });
            }
        }
    },[favoriteData, setCurrentResultSetHandler, pagiPage, results.length ]);


    
    // is loading
    const {favoriteLoading, favoriteLoadingUpdate, favoriteLoadingMatch } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(()=>{
       if(favoriteLoading || favoriteLoadingUpdate || favoriteLoadingMatch){
            setIsLoading(true);
            // cleanup
            return ()=> setIsLoading(false);
       }
    },[favoriteLoading, favoriteLoadingUpdate, favoriteLoadingMatch]);

    // is error
    const {favoriteError, favoriteErrorUpdate, favoriteErrorMatch, resetHandler } = props;
    const [errorMessage, setErrorMessage] = React.useState(null);
    React.useEffect(()=>{
       if(favoriteError || favoriteErrorUpdate || favoriteErrorMatch){
            favoriteError && setErrorMessage(favoriteError)
            favoriteErrorUpdate && setErrorMessage(favoriteErrorUpdate)
            favoriteErrorMatch && setErrorMessage(favoriteErrorMatch)
            // cleanup
            return ()=> {
                setErrorMessage(null);
                favoriteError && resetHandler("favoriteError")
                favoriteErrorUpdate && resetHandler("favoriteErrorUpdate")
                favoriteErrorMatch && resetHandler("favoriteErrorMatch")
            };
       }
    },[favoriteError, favoriteErrorUpdate, favoriteErrorMatch, resetHandler]);

    /*
    * PAGINATION HANDLERS
    */
    const pagiLinkHandler = (pagiPage)=>{
        // set current result set
        setCurrentResultSetHandler(pagiPage, false);
    }

    
return (
    <React.Fragment>
        <section >
            <PageTitle>Your <span>Favorite</span> Recipes</PageTitle>
                <section className={classes.ContentArea}>
                    <article    className="recipeListWrapper"  
                                style={ isLoading ? { minHeight:'75vh', position:'relative' } : {} } // align loading icon
                            >
                        {
                            // loading - only fetch loading
                            // we show others in add-to-fav icon
                            isLoading && !(favoriteLoadingUpdate || favoriteLoadingMatch) &&  <Loading />
                        }
                        {
                            // error - only fetch errors
                            // we show others in add-to-fav icon
                            !(favoriteErrorUpdate || favoriteErrorMatch) && favoriteError && (
                                                    <NotFound   title="Error!!!, a recipe of mess..."
                                                                subTitle={`${favoriteError} and cannot complete this task. please try the following...`} 
                                                                isError={true}
                                                        >   
                                                            <FaCircle />check your internet connection, 
                                                            <FaCircle />try refreshing your browser, 
                                                            <FaCircle />logout and re-login,  
                                                            <FaCircle />try back in few hours,  
                                                            <FaCircle />and if error still exists please contact us.
                                                    </NotFound>
                                                )
                        }
                        {
                            // no results found
                            !(isLoading || errorMessage) && currentResultSet && currentResultSet.results.length === 0 && (
                                                    <NotFound   title="Ops! you haven't found it yet..."
                                                                subTitle="Looks like you haven't picked any favorite recipes yet!" 
                                                        >   
                                                        <React.Fragment>
                                                            Please <a href="/">search recipes</a> and click favorite icon next to the recipe image to add that recipe to your favorites.
                                                        </React.Fragment>
                                                    </NotFound>
                                                )
                        }
                        {
                            // results
                            // fetchloading removed - avoid results jump
                            // show loading on top of results during fetch
                            currentResultSet.results && currentResultSet.results.length > 0 && (
                                            <ListRecipes    totalResults={currentResultSet.totalResults} 
                                                            perPage={perPage}
                                                            pagiPage={currentResultSet.pagiPage}
                                                            results={currentResultSet.results} 
                                                            // resultLoading={props.favoriteLoading} 
                                                            // resultError={props.favoriteError} 
                                                            pagiLinkHandler={pagiLinkHandler}
                                                            page='favorites'
                                                    />
                                        )
                        }
                    </article>
                </section>
        </section>
        <Route  path='/favorites/:recipeId' exact 
                render={ props => {
                    return <RecipeSingle {...props} />;
                }} 
            />
    </React.Fragment>
);

};

const mapStateToProps = state =>{
    return {
        authData: state.authReducer.authData,
        
        favoriteData: state.favoriteReducer.favoriteData,
        
        favoriteLoading: state.favoriteReducer.favoriteLoading,
        favoriteLoadingUpdate: state.favoriteReducer.favoriteLoadingUpdate,
        favoriteLoadingMatch: state.favoriteReducer.favoriteLoadingMatch,
        
        favoriteError: state.favoriteReducer.favoriteError,
        favoriteErrorUpdate: state.favoriteReducer.favoriteErrorUpdate,
        favoriteErrorMatch: state.favoriteReducer.favoriteErrorMatch,
    }
}
const mapDispatchToProps = dispatch =>{
    return {
        resetHandler: (property) => dispatch( actions.reset(property) ),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Favorites);