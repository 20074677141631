import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as actions from './../../../store/actions/actions';
import MainMenuList from './../MenuList';
import classes from './LoginMenu.module.css';
import { FaHeart, FaCarrot, FaLock, FaUserCircle  } from 'react-icons/fa';


const LoginMenu = (props)=> {

	// toggle login menu
	const [showMenu, setShowMenu ] = React.useState(false);
	const toggleLoginMenuHandler = (status)=>{
		setShowMenu(status);
	}

	// generate login menu list
	const initMenuItems = [
		{label: "Manage Favorites",  action:null, link:"/favorites", 	icon:<FaHeart />},
		{label: "Manage Pantry", 	 action:null, link:"/pantry", 		icon:<FaCarrot />},
		{label: "Logout", 			 action:()=>props.authLogoutHandler(), link:"/", 	icon:<FaLock />},
	];

	// login text
	// dynamic login/signup and user
	let loginText;
	if(props.authData){
		loginText=(
					<div className={classes.LoginIcon} 
						 //onClick={() => toggleLoginMenuHandler(true)} 
						 onMouseOver={() => toggleLoginMenuHandler(true)} 
						 onMouseOut={() => toggleLoginMenuHandler(false)} 
						 data-testid="login-icon"
						>
						<FaUserCircle aria-label="toggle login menu" />
						{/* login dopdown */}
						<div className={classes.Nav} 
							 data-testid="login-menu"
							 style={{ display: showMenu ? "flex" : "none" }}
							 onMouseOver={() => toggleLoginMenuHandler(true)} 
							 onMouseOut={() => toggleLoginMenuHandler(false)}
							 onClick={() => toggleLoginMenuHandler(false)}
							>
							{(props.authData) && <span className="user-name">welcome, {props.authData.displayName}</span>}
							<MainMenuList menuItems={initMenuItems} menuType='login' />
						</div>
					</div>
				);
	}else{
		loginText=(
					<div className={classes.LoginIcon} >
						<Link to={`${props.history.location.pathname}?prompt=login`} >Login</Link>
						 / 
						<Link to={`${props.history.location.pathname}?prompt=signup`} >Signup</Link>
					</div>
				);
	}

	return (
		<React.Fragment>
			{loginText}
		</React.Fragment>
	);
	
};

const mapStateToProps = state => {
	return {
		authData: state.authReducer.authData,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		authLogoutHandler: () => dispatch( actions.auth_logout() ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginMenu));