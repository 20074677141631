import * as actionTypes from './actionTypes.js';
import axios from 'axios';

// start
export const search_start = ()=>{
	return{
		type: actionTypes.SEARCH_START,
		searchError: null,	// reset
		searchLoading: true,
	}
}

// success
export const search_success = (data, redirectLink)=>{
	return{
		type: actionTypes.SEARCH_SUCCESS,
		searchError: null,
		searchLoading: false,
		searchData: data,
		searchRedirect: redirectLink,
	}
}

// fail
export const search_fail = (error, redirectLink)=>{
	return{
		type: actionTypes.SEARCH_FAIL,
		searchError: error,
		searchLoading: false,
		searchData: null,
		searchRedirect: redirectLink,
	}
}

// set last search query
export const search_setpagi = (pagiData)=>{
    return{
        type: actionTypes.SEARCH_SETPAGI,
        searchPagi: pagiData,
    }
}

		/*
		* HELPER ACTIONS
		*/
		
		// set search queries
		export const search_setquery = (query)=>{
            // get current searchQuery
            return {
                type: actionTypes.SEARCH_SETQUERY,
			    searchQuery: query,
            }
		}

        // set search queries
        export const search_setfilter = (query)=>{
            // get current searchQuery
            return {
                type: actionTypes.SEARCH_SETFILTER,
                searchFilter: query,
            }
        }

        // set redirect link
        // loginsubmit success and fail 
        // handles redirects within
        export const search_setredirect = (link)=>{
            return{
                type: actionTypes.SEARCH_SETREDIRECT,
                searchRedirect: link,
            }
        }

		// reset
		export const reset = (resetProp)=>{
			console.log('***reset call:');
            return{
				type: actionTypes.RESET,
				[resetProp]: null,
			}
		}

// async submit
// we take search query from store 
// seachQuery - temp params
export const search_submit = (redirectLink='/', searchQuery={}, saveQuery=false)=>{

	return (dispacth, getState) => {
        // start
		dispacth( search_start() );
		
		// setup request headers        
        const gcfUrl      = process.env.REACT_APP_GCF_URL;
        const gcfEndpoint = 'searchrecipe'; 
        let param         = {
                                params:{
                                    ...getState().searchReducer.searchQuery,    // apply searchQueries
                                    ...searchQuery,                             // apply custom searchQueries
                                    // ...searchFilter,   // apply filters
                                    // includeIngredients:"garlic,butter,ginger,salt,asparagus spears,olive oil",
                                },
                            };
        
        // request
        axios.get(gcfUrl+gcfEndpoint, param)
        // request
		// axios.get(url, param )
		.then(response=>{
            /*
            * INGREDIENTS MATCH %
            * filter results by ingredient maching % selected by user
            * - filter saved fetch by % of ingredient match
            * - re-feed the results as list
            */
                // MATCH INGREDIENTS
                const pantryData = getState().pantryReducer.pantryData;
                response.data.results.forEach( (recipe, key) => {
                    //missedIngredientCount
                    //missedIngredients
                    //usedIngredientCount
                    //usedIngredients

                    // check if missedIngredients
                    // are in the pantry
                    recipe.missedIngredients.forEach( (ing, index) => { 
                        if(pantryData && ing.id in pantryData){
                            // add to used and update count
                            recipe.usedIngredients.push(ing);
                            recipe.usedIngredientCount++;
                            // remove from missed
                            recipe.missedIngredients.splice(index, 1);
                            recipe.missedIngredientCount--;
                        }
                    });

                });

                // SORT LIST
                // compare usedIngredientCount
                response.data.results.sort( ( a, b )=> {
                    if ( a.usedIngredientCount > b.usedIngredientCount ){
                        return -1;
                    }
                    if ( a.usedIngredientCount < b.usedIngredientCount ){
                        return 1;
                    }
                    return 0;
                });


            // save to localstorage
			sessionStorage.setItem('searchData', JSON.stringify(response.data));

			// dispatch success
			// all search redirections are
			// always handled here 
			// dispacth( search_success(response.data, redirectLink+'?search=1') );
            dispacth( search_success(response.data, redirectLink) );
            

            // update searchQuery if request
            if(saveQuery){
                let currentSearchQuery = searchQuery;
                // if titleMatch (featured)
                // we switch search type to 'query'
                if( 'titleMatch' in searchQuery && searchQuery.titleMatch ){
                    currentSearchQuery = {...currentSearchQuery, titleMatch:null, query:searchQuery.titleMatch};
                }
                // update
                dispacth( search_setquery(currentSearchQuery) );
            }

		})
		.catch(error=>{
			console.log('**search error:');
			// mark error and redirect to search results
            dispacth( search_fail(error, redirectLink) );
		});
		
	}
}




/*
* AUTO COMPLETE
*/

// start
export const search_autocomplete_start = ()=>{
    return{
        type: actionTypes.SEARCH_AUTOCOMPLETE_START,
        searchAutocompleteError: null,  // reset
        searchAutocompleteLoading: true,
    }
}

// success
export const search_autocomplete_success = (data)=>{
    return{
        type: actionTypes.SEARCH_AUTOCOMPLETE_SUCCESS,
        searchAutocompleteError: null,
        searchAutocompleteLoading: false,
        searchAutocompleteData: data,
    }
}

// fail
export const search_autocomplete_fail = (error)=>{
    return{
        type: actionTypes.SEARCH_AUTOCOMPLETE_FAIL,
        searchAutocompleteError: error,
        searchAutocompleteLoading: false,
        searchAutocompleteData: null,
    }
}

// async search autocomplete
export const search_autocomplete_submit = (query, searchType)=>{

    return dispacth => {
        // start
        dispacth( search_autocomplete_start() );
                
        // setup request headers
        //let key  =process.env.REACT_APP_SPC_API;
        //let spcrul  = process.env.REACT_APP_SPC_API_URL;
        //let type="autocomplete";
        //let url  = spcrul+'/recipes/'+type+'?apiKey='+key;
                
        const gcfUrl      = process.env.REACT_APP_GCF_URL;
        const gcfEndpoint = 'autocomplete'; 
        const param       = {
                                params:{
                                    query:query,
                                    number:20,
                                }
                            };
        
        // request
        axios.get(gcfUrl+gcfEndpoint, param)
        //axios.get(url, param )
        .then(response=>{
            // dispatch success
            dispacth( search_autocomplete_success(response.data) );
        })
        .catch(error=>{
            console.log('**autocomplete error:');
            // mark error and redirect to search results
            dispacth( search_autocomplete_fail(error) );
        });
        
    }
}