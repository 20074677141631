import React from 'react';
import {Link} from 'react-router-dom';

import classes from './Login.module.css';
import Button from './../UI/Button';
import Input from './../UI/Input';
import Alert from './../Alert/Alert';


const LoginForm = (props)=>{

		return (
			<form onSubmit={props.handleSubmit} autoComplete="off" data-testid='login-form'>
				{ (props.authError) && <Alert  message={props.authError.message} type="error" reset="authError" /> }
				<Input 	name="user_email"
						id="user_email"
						label="Email"
						labelClass="sr-only"
						type="email"  
						inputClass={props.errors.user_email && props.touched.user_email?'has-error':''}
						placeholder="E-mail"
						action={props.handleChange}
						blur={props.handleBlur}
    					value={props.values.user_email}								
						errorText = {props.errors.user_email && props.touched.user_email ? props.errors.user_email : null }
					/>
				<Input 	name="user_password"
						id="user_password"
						label="Password"
						labelClass="sr-only"
						type="password"
						inputClass={props.errors.user_password && props.touched.user_password?'has-error':''}
						placeholder="Password"
						action={props.handleChange}
						blur={props.handleBlur}
						value={props.values.user_password}
						errorText={props.errors.user_password && props.touched.user_password ? props.errors.user_password : null }
					/>
				<div 	className={classes.BtnWrapper}
						style={{textAlign:"center", marginTop:"2em"}} >
					<Button type="submit"
							text="Login"
						/>
					<Link 	className={classes.SignupLoginLink}
							to={`${props.currentPath}?prompt=signup`}
						>Don't have an account, signup</Link>
					<Link 	className={`${classes.SignupLoginLink} resetpasswordlink`}
							to={`${props.currentPath}?prompt=resetpasswordlink`}
						>Forgot password?</Link>
				</div>
			</form>
		);
};

export default LoginForm;