import React from 'react';
import {Link } from 'react-router-dom';

import classes from './Login.module.css';
import Button from './../UI/Button';
import Input from './../UI/Input';
import Alert from './../Alert/Alert';


const SignupForm = (props)=>{
	
	return (
		<form onSubmit={props.handleSubmit} autoComplete="off">
			{ (props.authError) && <Alert  message={props.authError.message} type="error" reset="authError" /> }
			<Input 	name="user_name"
					id="user_name"
					label="Full Name"
					labelClass="sr-only"
					type="text"
					placeholder="Full Name"
					inputClass={props.errors.user_name && props.touched.user_name?'has-error':''}
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.user_name}								
					errorText = {props.errors.user_name && props.touched.user_name ? props.errors.user_name : null }
				/>
			<Input 	name="user_email"
					id="user_email"
					label="Email"
					labelClass="sr-only"
					type="email"  
					inputClass={props.errors.user_email && props.touched.user_email?'has-error':''}
					placeholder="E-mail"
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.user_email}								
					errorText = {props.errors.user_email && props.touched.user_email ? props.errors.user_email : null }
				/>
			<Input 	name="user_password"
					id="user_password"
					label="Password"
					labelClass="sr-only"
					type="password"
					inputClass={props.errors.user_password && props.touched.user_password?'has-error':''}
					placeholder="Password"
					action={props.handleChange}
					blur={props.handleBlur}
					value={props.values.user_password}
					errorText={props.errors.user_password && props.touched.user_password ? props.errors.user_password : null }
				/>
			<div 	className={classes.BtnWrapper}
					style={{textAlign:"center", marginTop:"2em"}} >
				<Button type="submit"
						text="Signup"
					/>
				<Link 	className={classes.SignupLoginLink}
						to={`${props.currentPath}?prompt=login`}
					>Already have an account, login</Link>
			</div>
		</form>
	);
};


export default SignupForm;