import React from 'react';
import {Link} from 'react-router-dom';

import logo from './logo.svg';

const Comp = (props)=> {

	return (
		<Link className="logo" to="/">
			<img src={logo} height="32" width="auto" alt="Logo" />
		</Link>
	);
};

export default Comp;