import React from 'react';
import {} from 'react-router-dom';

import classes from './IntroPrompt.module.css';
import withModal from './../../HOC/withModal';
import Input from './../UI/Input';
import Button from './../UI/Button';
import bgImg from './bgIntro.png';
import introGraphicSignup from './intro-graphic-signup.png';
import introGraphicPantry from './intro-graphic-pantry.png';
import introGraphicResults from './intro-graphic-results.png';

const IntroPrompt = (props)=> {

	// hide intro after first-visit
	// for the rest of the session
	// save to sessionStorage
	// access in Auth.js to maintain display
	const isSession = sessionStorage.getItem('introHidePref');
	if(!isSession){
		sessionStorage.setItem('introHidePref', '1');
	}

	// opt-out redisplay checkbox
	// save to localStorage
	// access in Auth.js to maintain display
	const [introHide, setIntroHide] = React.useState(false);
	if(introHide){
		localStorage.setItem('introHidePref', '1');
	}else{
		localStorage.removeItem('introHidePref');
	}


	return (
		<React.Fragment>
			<div className={`${classes.Wrapper} ${classes.IntroWrapper}`} >
				<p><span className="text-primary">Match&Cook</span> works better with ingredients! <strong>Setup is easy and only takes less than 2 minute.</strong></p> 
				<ul className={classes.List}>
					<li>
						<img src={introGraphicSignup} alt="intro graphic signup" />
						<h3>Signup</h3>
						{/*<p>Signup and create your account. Signup is fast and secure.</p>*/}
					</li>
					<li>
						<img src={introGraphicPantry} alt="intro graphic pantry" />
						<h3>Setup Pantry</h3>
						{/*<p>From predefined lists or manually add ingredients to your pantry.</p>*/}
					</li>
					<li>
						<img src={introGraphicResults} alt="intro graphic results" />
						<h3>Search</h3>
						{/*<p>Then we match your search with existing pantry ingredients.  </p>*/}
					</li>
				</ul>
				
				<p>
					<strong>Don't have 2 minutes?</strong> You can still search and match, but ingredients need to be reentered at every new search.
				</p>
			</div>
			<div className={classes.footerWrapper} >
			    <Button action={()=>props.closeModalHandler(`${props.currentPath}?prompt=signup`)} 
						text="Signup"
					/>
				<Button action={()=>props.closeModalHandler(`${props.currentPath}?prompt=login`)} 
						btnclass="btn-secondary"
						text="Login"
					/>
				<Input 	wrapperClass={classes.ShowIntroCheckbox}
						name="show_intro_prompt"
						id="show_intro_prompt"
						label=" Don't show this message again."
						labelClass="label-right"
						type="checkbox"
						initialState={introHide}
						action={()=>setIntroHide(!introHide)}
					/>
			</div>
		</React.Fragment>
	);
};

// set modal defaults
const modalDefaults = {
	title: <React.Fragment>Better with <span>Ingredients!</span></React.Fragment>,
	width:'660px',
	bgImg: bgImg,
};

export default withModal(IntroPrompt, modalDefaults);