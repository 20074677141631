import React from 'react';

import { FaPagelines  } from 'react-icons/fa';
import Card from './../Card/Card';
import Pagination from './Pagination';

const ListRecipes = (props)=> {

	// generate recipe list
	const recipeList = props.results.map(data=>{
		// generate taglist
		const ingTagList = [];
		// used ings tags
		if(data.usedIngredients){
			data.usedIngredients.forEach(ing=>{
				ingTagList.push({id:ing.id, label:ing.name, status: true});
			});
		}
		// missed ings tags
		if(data.missedIngredients){
			data.missedIngredients.forEach(ing=>{
				ingTagList.push({id:ing.id, label:ing.name, status: false});
			});
		}
		
		// generate recipe facts
		let totalIngs = Number(data.missedIngredientCount+data.usedIngredientCount);
		let usedIng = Number(data.usedIngredientCount)
		let ingCount = usedIng+"/"+totalIngs;
		let ingCountPer = ((usedIng/totalIngs)*100).toFixed(0);
		const factsList = [
			{label: "avilIngs", data: `${ingCountPer}% match (${ingCount})`, icon: <FaPagelines /> },
		];
		
		return <Card 	key={data.id} 
						image={data.image} 
						id={data.id} 
						title={data.title}
						factsList={factsList} 
						ingTagList={ingTagList}
					/>
	});


	const pagiList = <Pagination 	totalResults={props.totalResults} 
			                        perPage={props.perPage}
			                        pagiPage={props.pagiPage} 
			                        pagiLinkHandler={props.pagiLinkHandler}
			                    />
	
	return (	
		<React.Fragment>
			{pagiList}
			{recipeList}
			{pagiList}
		</React.Fragment>
	);
};


export default ListRecipes;