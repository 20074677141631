import React from 'react';
import {connect} from 'react-redux';
import {usePrevious} from './../../Hooks/Hooks';

import { FaTrash, FaCircleNotch } from 'react-icons/fa';
import classes from './PantryIng.module.css';
import PantryIngTableFilter from './PantryIngTableFilter.js';


const PantryIngTable = (props)=> {
	
	// FILTERS 
	// showcart only
	const [displayShopOnly, setDisplayShopOnly] = React.useState(false);
	// keyword only
	const [filterSearchQuery, setFilterSearchQuery] = React.useState('');
	const filterSearchHandler = (event) => {
		const value = event.target.value;
		setFilterSearchQuery(value);
	}

	const [loadElm, setLoadElm] = React.useState(null);
	const [errorElm, setErrorElm] = React.useState(null);
	const [targetElm, setTargetElm] = React.useState(null);

	const favCartandDeleteToggle = (event, id, action) => {
		event.persist();
		setLoadElm(document.getElementById(`${id}_${action}_loading`));
		setErrorElm(document.getElementById(`${id}_${action}_error`));
		setTargetElm( action === 'cart' ? event.target : event.target.parentNode); // gives <path> we need <svg>
		// update FB
		action === 'cart' && props.addToShopHandler(event, id);
		action === 'delete' && props.deleteIngHandler(event, id);
	}

	// loadElm
	React.useEffect(()=>{
		if(loadElm){
			if(props.pantryLoadingUpdate){
				loadElm.style.display = 'inline';
				targetElm.style.display = 'none';
				return ()=>setLoadElm(null);
			}else{
				loadElm.style.display = 'none';
				targetElm.style.display = 'inline';
				return ()=>setTargetElm(null);
			}
		}
	},[loadElm, targetElm, props.pantryLoadingUpdate]);
	
	// errorElm
	React.useEffect(()=>{
		if(errorElm){
			if(props.pantryErrorUpdate){
				errorElm.style.display = 'inline';
				return ()=>setErrorElm(null);
			}else{
				errorElm.style.display = 'none';
			}
		}
	},[targetElm, errorElm, props.pantryErrorUpdate]);


	// TABLE ROWS
	
	// previous values
	// add props.resultsArray ids
	const prevResults = usePrevious(props.resultsArray && props.resultsArray.map(ing => ing.id) );


	
	// generate pantry rows
	const tableRows = [];
	// generate list
	props.resultsArray.forEach( (ing, key) =>{
		// filter shop-only
		if(displayShopOnly && ! ing.cart){
			return ;
		}

		// mark items as added/deleted
		// added - not prev
		// deleted - not in current
		let isAdded = false;
		if( prevResults && prevResults.length && !prevResults.includes(ing.id) ){
		 	// added
		 	if(prevResults.length < props.resultsArray.length){
		 		isAdded = 'added';
		 	}
		 	// deleted
		 	else if(prevResults.length > props.resultsArray.length){
		 		isAdded = 'deleted';
		 	}
		}
		//  new row fade
		const newRowFade = ()=>setTimeout( ()=> document.getElementById(ing.id).classList.remove('short-highlight'), 200 );

		// filter keyword
		// ignore if not in search query
		if(filterSearchQuery && filterSearchQuery.length ){
			let aisleLC  = ing.aisle.toLowerCase();
			let labelLC  = ing.label.toLowerCase();
			let filterLC = filterSearchQuery.toLowerCase();
			if( !(labelLC.includes(filterLC) || aisleLC.includes(filterLC)) ){
				return;
			}
		}


		tableRows.push(
				<tr 	key={ ing.id}
						id={ ing.id}
						className={`${ing.cart && "shop"} ${isAdded && "short-highlight"}`}
						onLoad={ isAdded ? newRowFade() : null }
					>
		            <td>{ ing.label}</td>
		            <td>{ ing.aisle}</td>
		            <td>
		            	<FaCircleNotch 	id={ing.id+'_cart_loading'} 
		            					style={{display:'none'}}
		            					className="filter-load-indicator fa-spinner" 
		            				/>
		            	<span 			id={ing.id+'_cart_error'} 
		            					style={{display:'none'}}
		            					className="load-error-small"
		            				>error</span>
		            	<input 			name={ ing.id} 
				            			className={classes.CartCheck}
				            			type="checkbox"  
				            			value={ ing.id} 
				            			defaultChecked={ ing.cart }
				            			disabled={ props.pantryLoadingUpdate }
				            			onChange={(event)=>favCartandDeleteToggle(event, ing.id, 'cart')}
		            				/>
		            </td>
		            <td>
		            	<FaCircleNotch 	id={ing.id+'_delete_loading'} 
		            					style={{display:'none'}}
		            					className="filter-load-indicator fa-spinner" 
		            				/>
		            	<span 			id={ing.id+'_delete_error'} 
		            					style={{display:'none'}}
		            					className="load-error-small"
		            				>error</span>
		            	<FaTrash 		className={`${classes.IconDelete} ${props.pantryLoadingUpdate && 'disabled'}`}
					            		onClick={(event)=>{
					            				// add fadeclass
												document.getElementById(ing.id).classList.add('short-highlight-delete');					            				
					            				favCartandDeleteToggle(event, ing.id, 'delete')
					            		}}
					            	/>
		            </td>
		        </tr>
		);
	})

	return (
		<div className={classes.ContentArea}>
            <React.Fragment>
                <PantryIngTableFilter 	shopListFilterHandler={()=>setDisplayShopOnly(!displayShopOnly)} 
					filterSearchHandler={filterSearchHandler} 
				/>
				<table className={classes.Table}>
				    <thead>
				        <tr>
				            <th>Ingredient</th>
				            <th>Asile</th>
				            <th>Shop</th>
				            <th></th>
				        </tr>
				    </thead>
				    <tbody>
				        { tableRows }
				    </tbody>
				</table>
            </React.Fragment>
		</div>
	);


};


const mapStateToProps = state =>{
    
    return {
        pantryLoading: state.pantryReducer.pantryLoading,
        pantryLoadingUpdate: state.pantryReducer.pantryLoadingUpdate,
        pantryLoadingMatch: state.pantryReducer.pantryLoadingMatch,
        
        // pantryError: state.pantryReducer.pantryError,
        pantryErrorUpdate: state.pantryReducer.pantryErrorUpdate,
        pantryErrorMatch: state.pantryReducer.pantryErrorMatch,     
    }
}

export default connect(mapStateToProps, null)(PantryIngTable);