import * as actionTypes from './../actions/actionTypes';

export const pantryInitialState = {
						pantryActionType:null,	// fetch, update [add, delete], match
						pantryLoading: false,
						pantryLoadingUpdate: false,
						pantryLoadingMatch: false,
						pantryError:null,
						pantryErrorUpdate:null,
						pantryErrorMatch:null,
						pantryData:null,
					};

const pantryReducer = (state = pantryInitialState, action)=>{

	switch(action.type){
		case actionTypes.PANTRY_START: return{ ...state, ...action}; 
		case actionTypes.PANTRY_SUCCESS: return{ ...state, ...action}; 
		case actionTypes.PANTRY_FAIL: return{ ...state, ...action}; 
		case actionTypes.PANTRY_ACTIONTYPE: return{ ...state, ...action}; 
		case actionTypes.RESET: return{ ...state, ...action};
		
		default: return state;
	}

};

export default pantryReducer;