import React from 'react';
import {connect} from 'react-redux';

import IngJson from './../../matchandcook_ingredients.json';
import * as actions from './../../store/actions/actions';

import classes from './../Search/SearchForm/SearchForm.module.css';
import { FaPlusCircle, FaCircleNotch  } from 'react-icons/fa';

import {Chip, InputAdornment, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';


const IngSearchForm = (props, state)=> {

// 02. SEARCH SUGGESTIONS
// INGREDIENT NAMES	
// RECIPE NAMES
    // state - suggestion options list
    const [options, setOptions] = React.useState([]);
	// current input text
    const [currentInput, setCurrentInput] = React.useState(null);
    // is adding still loading
    // we count from match -> update -> fetch
    // mark loading when add event request
    const [isAddLoading, setIsAddLoading] = React.useState(false);
    // unmark loading when fetch completed
    // or fetch/update/match error
    React.useEffect(()=>{
    	if(!props.pantryLoading || props.isMatchUpdateError || props.pantryError){
    		setIsAddLoading(false);
    	}
    },[props.pantryLoading, props.pantryError, props.isMatchUpdateError]);

    // when user types search text
    // set search query
    const setOptionsHandler = (event, value, reason)=>{
    	// clear existing suggestions
    	setOptions([]);
    	setCurrentInput(value);
    }

    // generate suggestion list
    React.useEffect(()=>{	    
		const ingOptions = IngJson.map( option => option.label);
		// set suggestion list
		setOptions(ingOptions);	
    },[currentInput, setOptions ]);

    // generate recipe names list
    // when fetch data available
    React.useEffect(()=>{
    	if(props.searchAutocompleteData){
    		// create option list
    		const resultArry = props.searchAutocompleteData.map( (recipe) => {
				return recipe['title'];
			});
			// set suggestion list
			setOptions(resultArry);
    	}
    },[props.searchAutocompleteData]);

	// new suggestions
	// add user's input as new suggest-options
	const filter = createFilterOptions();
	const filterOptionsHandler = (options, params)=>{	
		const filtered = filter(options, params);
		// generate new value
	    if (params.inputValue !== '') {
	      filtered.push(`Add "${params.inputValue}"`);
	    }
	    return filtered;
	}
	
	// generate tags
	// render search term tags
	const renderTagsHandler = (value, getTagProps)=>{
		const values = value.map((option, index) => {
			// remove 'add' from value
			option = option.replace('Add "', '').replace('"', '');
			// create tag
        	return <Chip 	size="small"
		        			label={option} 
		        			{...getTagProps({ index })} 
			        		//onClick={}
			        		//onDelete={}
		        		/>
		});
	  	return values;
	}


// 03. UPDATE SEARCH QUERY
// we use saved searchQuery from store
// during api fetch 
	const [inputQuery, setInputQuery] = React.useState([]);
	// onchange user input
	const  setInputQueryHandler = (event, value, reason)=>{
		// val - search-text
		const queryText = value.map((val) => {
			// remove 'add' from value
			return val.replace('Add "', '').replace('"', '');
		});
		//setInitialSearchQuery( searchQuery.includeIngredients.split(',') );
		setInputQuery(queryText);
	}


// 04. GENERATE SEARCH TEXT-FIELD
// add search icon
	const renderInputHandler = (params)=>{
		let paramsInputProps = params.InputProps;
		return  (
            <TextField 	{...params}
  					variant="outlined" 
  					label="Enter ingredients to add"
  					InputProps={{
						...paramsInputProps,		          
			          	endAdornment: (
			            	<InputAdornment position="end" 
			            					className={`${classes.SearchIcon} iconSearch`} 
			            				>
				              	{	
				              		// loading - show circle
				              		(isAddLoading) && <button type="button" ><FaCircleNotch className="fa-spinner" /></button> 
								}
				              	{	
				              		// add - show + icon
				              		(inputQuery.length > 0 && !isAddLoading) && <button type="submit" ><FaPlusCircle /></button> 
			          			}
			            	</InputAdornment>
			          	),
			        }}
  				/>
        );
	};


// 05. SEARCH SUBMIT
// trigger search fetch
	const searchHandler = (event)=>{
		event.preventDefault();
		// mark as loading
		setIsAddLoading(true);
				
		// submit fetch
		if(inputQuery.length > 0){			
			// match ing in the query and update pantry
			props.pantryMatchIngsAndUpdate(inputQuery);
		}
		// clear search input
		setInputQuery([]);
		// blur out to close the dropdown
		document.getElementById('search-ing-input').blur();
	}


// TODO: REMOVE SELECTED FROM AUTOSUGGEST
// TODO: 


return (
    
    <form 	onSubmit={searchHandler}
    		id="search-ings"
    		className={classes.SearchForm}
    		>
	    <Autocomplete
	        id="search-ing-input"
	        name="search-ing-input"
	    	
	        freeSolo
	    	disableCloseOnSelect	// stays open
	        filterSelectedOptions
	    	autoHighlight={true}
	    	//autoSelect={true}
	    	multiple={true}
	        disableClearable={true}

	        // defaultValue={ inputQuery }
	        value={ inputQuery }
	        options={options}
	        
	        onChange={(event, value, reason)=>setInputQueryHandler(event, value, reason)}
	        onInputChange={(event, value, reason)=>setOptionsHandler(event, value, reason)}
	    	filterOptions={(options, params) => filterOptionsHandler(options, params) }
	        renderInput={(params) => renderInputHandler(params) }
	        renderTags={(value, getTagProps) => renderTagsHandler(value, getTagProps) }

	   		/>
    </form>
);

};

const mapStateToProps = state =>{
	return {
		//searchQuery: state.searchReducer.searchQuery,
		authData: state.authReducer.authData,		
		searchAutocompleteData: state.searchReducer.searchAutocompleteData,
		pantryData: state.pantryReducer.pantryData,		
		pantryLoading: state.pantryReducer.pantryLoading,		
		pantryError: state.pantryReducer.pantryError,		
	}
}

const mapDispatchToProps = dispatch =>{
	return {
		pantryUpdateHandler: (user, Data, updateType) => dispatch( actions.pantry_update(user, Data, updateType) ),
        pantryMatchIngsAndUpdate: (query) => dispatch( actions.pantry_matchIngsToQuery(query) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IngSearchForm);