import React from 'react';

const Button = (props)=> {

	return (
		<button type={props.type} 
				onClick={props.action} 
				className={props.btnclass}
			>{props.text}</button>
	);
};

export default Button;