import React from 'react';

import classes from './ListFacts.module.css';
import ListFact from './ListFact';

const ListFacts = (props)=> {

	const factsList = props.factsList.map(fact=>{
		return <ListFact key={fact.label}>{fact.icon} {fact.data}</ListFact>; 
	});

	return (
		<div className={classes.FactList}>
			{factsList}
		</div>
	);
};

export default ListFacts;