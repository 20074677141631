export const RESET                       = 'RESET';

export const AUTH_START                  = 'AUTH_START';
export const AUTH_SUCCESS                = 'AUTH_SUCCESS';
export const AUTH_SUCCESS_RESETPASSWORD  = 'AUTH_SUCCESS_RESETPASSWORD';
export const AUTH_FAIL                   = 'AUTH_FAIL';
export const AUTH_SETREDIRECT            = 'AUTH_SETREDIRECT';
export const AUTH_LOGOUT                 = 'AUTH_LOGOUT';

export const SEARCH_START                = 'SEARCH_START';
export const SEARCH_SUCCESS              = 'SEARCH_SUCCESS';
export const SEARCH_FAIL                 = 'SEARCH_FAIL';
export const SEARCH_SETREDIRECT          = 'SEARCH_SETREDIRECT';
export const SEARCH_SETQUERY             = 'SEARCH_SETQUERY';
export const SEARCH_SETFILTER            = 'SEARCH_SETFILTER';
export const SEARCH_SETPAGI              = 'SEARCH_SETPAGI';

export const SEARCH_AUTOCOMPLETE_START   = 'SEARCH_AUTOCOMPLETE_START';
export const SEARCH_AUTOCOMPLETE_SUCCESS = 'SEARCH_AUTOCOMPLETE_SUCCESS';
export const SEARCH_AUTOCOMPLETE_FAIL    = 'SEARCH_AUTOCOMPLETE_FAIL';

export const PANTRY_START                = 'PANTRY_START';
export const PANTRY_SUCCESS              = 'PANTRY_SUCCESS';
export const PANTRY_FAIL                 = 'PANTRY_FAIL';
export const PANTRY_ACTIONTYPE   	 	 = 'PANTRY_ACTIONTYPE';

export const FAVOURITE_START             = 'FAVOURITE_START';
export const FAVOURITE_SUCCESS           = 'FAVOURITE_SUCCESS';
export const FAVOURITE_FAIL              = 'FAVOURITE_FAIL';