import React from 'react';

import classes from './PantryIng.module.css';
import PantryPredefinedIngListItem from './../Pantry/PantryPredefinedIngListItem';
import { FaHeartbeat, FaCookieBite, FaUserTie, FaUser, FaSeedling, FaUserGraduate, FaPepperHot, FaGlassCheers, FaHotdog, FaMugHot, FaPizzaSlice, FaCocktail } from 'react-icons/fa';
import Button from './../UI/Button';
import withModal from './../../HOC/withModal';
import bgImg from './bgPredefinedIngList.png';

// set modal defaults
const modalDefaults = {
    	title: <React.Fragment><span>Predifined</span> Lists</React.Fragment>,
        width:'680px',
        bgImg: bgImg,
        display: true,
        top: '2%',
        transform: 'translate(-50%, 0)',
};

const predefinedList = [
		{ id:"healthy", icon:<FaHeartbeat />, title:"Common Pantry", summary:"Includes basic ingredients for your favorite recipes. From baking goods, like sugar and flour, to canned goods and condiments,"  },
		{ id:"plant-base", icon:<FaSeedling />, title:"Vegan Diet Pantry", summary:"Simple list of kitchen essentials with protein rich plant-based shopping list for vegans."  },
		{ id:"snacks", icon:<FaCookieBite />, title:"Healthy Snacks", summary:"Snack grocery list ingredients for cheap and healthy snacks."  },
		{ id:"spices", icon:<FaPepperHot />, title:"Herb & Spice Pantry", summary:"Common list of herbs and spices that you should have in your spice rack."  },
		{ id:"spreads", icon:<FaHotdog />, title:"Common Spread List", summary:"Grocery list for common spreads and spreadables to make more fun and out-of-the-box ideas."  },
		{ id:"cheat-day-diet", icon:<FaPizzaSlice />, title:"Cheat Day Pantry", summary:"Grocery list to make your cheat days snacks easy and tasty."  },
		{ id:"for-one", icon:<FaUser />, title:"Single's Pantry", summary:"Common, easy to prepare recipe ingredient list for a single man or woman."  },
		{ id:"singleguy", icon:<FaUserTie />, title:"Bachelor's Pantry'", summary:"Basic non-perishable or frozen food items for bachelor for whipping up culinary delights in the kitchen."  },
		{ id:"college", icon:<FaUserGraduate />, title:"College Pantry", summary:"A college student's grocery list with ingredients key to a healthy mind and body."  },
		{ id:"brunch", icon:<FaMugHot />, title:"Brunch Pantry", summary:"Grocery list for easy and interesting brunch ideas (bit of international flavor)."  },
		{ id:"parties", icon:<FaCocktail />, title:"Small Party Pantry", summary:"Grocery list for quick and easy snacks when friends come over."  },
		{ id:"new-year-resolution", icon:<FaGlassCheers />, title:"New Year Resolution", summary:"Grocery list for your New Year's resolution, whether it is to eat healthier or save money."  },	
	];


const PantryPredefinedIngList = (props)=> {
	
	// SET CURRENT SELECTION
	const [currentSelection, setCurrentSelection ] = React.useState([]);
	const selectedPredefinedHandler = itemid => {
		const selection = [];
		// remove request
		// if already in
		currentSelection.forEach(id => {
			if(itemid !== id){
				selection.push(id);
			}	
		});
		// add if not in
		if( !currentSelection.includes(itemid) ){
			selection.push(itemid);
		}
		// update state	
		setCurrentSelection([...selection]);
	}

	// GENERATE ITEM LIST
	const predefinedListItems = [];
	predefinedList.forEach( item=>{
		predefinedListItems.push(
				<PantryPredefinedIngListItem
					key={item.id}
					icon={item.icon}
					label={item.title}
					summary={item.summary}
					isSelected={ currentSelection.includes(item.id) }
					addPredefinedIngHandler={()=>selectedPredefinedHandler(item.id)}
				/>
			);
	})

	return (
		<React.Fragment>
			<div className={`${classes.Wrapper} ${classes.PredefinedList}`} >
				<p>To make things easier, we made a few pantry lits for you. You can start by choosing one or more from these presets and modify later.</p>
				{predefinedListItems}
			</div>
			<div className={classes.footerWrapper} >
				{
	              	<div style={{textAlign:"center", marginTop:"2em"}} >
					   <Button type="button" 
							action={()=>{
								props.closeModalHandler('/pantry');
								props.addPredefinedIngHandler(currentSelection, 'add');
							}}
							btnclass="sm"
							text="Add to pantry"
						/>
						<Button type="button" 
							action={()=>{
								props.closeModalHandler('/pantry');
								props.addPredefinedIngHandler(currentSelection, 'replace');
							}}
							btnclass="btn-secondary sm"
							text="Replace with existing Pantry"
						/>
				    </div>
	            }
			</div>
		</React.Fragment>
	);

};

export default withModal(PantryPredefinedIngList, modalDefaults);