import React from 'react';

// import { connect } from 'react-redux';
// import * as actions from './../../store/actions/actions';

import {Formik} from 'formik';
import * as Yup from 'yup';

import withModal from './../../HOC/withModal';
import ForgotPasswordForm from './ForgotPasswordForm';
import bgImg from './bgLogin.png';
import Alert from './../Alert/Alert';



export const formikConfig = {
		initialValues:{ user_email:''},
		validationSchema:Yup.object({
			                    user_email: Yup.string()
			                    				.email('Invalid email')
			                    				.required('Required'),
	    				}),
};


const ForgotPassword = (props)=> {
	// output if succss message
	if(props.authResetPasswordSuccess){
		return (
				<Alert  message={`We sent an email to ${props.authResetPasswordSuccess.email} with password reset instructions. Please check your email and follow instructions to reset your password.`} 
						type="success"
						classes="regular"
						reset="authResetPasswordSuccess" />
			);
	}

	// output form
	return (
		<Formik {...formikConfig} 
				onSubmit={ (values)=> {
						// modal close will be handled
						// during redirection in auth
						props.authSubmitResetPWHandler(values, 'resetpasswordlink', props.currentPath);
					} }
			>
      			{  
      				(formikProps) => {
      					return (
      						<ForgotPasswordForm 	{...formikProps} 
      									currentPath={props.currentPath} 
      									authError={props.authError}
      									/> 
      					);
      				}
      			}	
      	</Formik>
	);

};

// set modal defaults
const modalDefaults = {
	title: <React.Fragment>Password Reset</React.Fragment>, 
	width:'400px',
	bgImg: bgImg,
};



export default withModal(ForgotPassword, modalDefaults);