import React from 'react';

import ListTag from './ListTag';

const ListTags = (props)=> {

	const ingTagList = props.ingTagList.map((tag,key)=>{
		return (
					<ListTag 	key={props.cardId+'-'+tag.label+'-'+tag.id+'-'+key} 
								id={tag.id} 
								status={tag.status}
								label={tag.label}
							/>
			); 
	});

	return (
		<div className="tagList" >
			{ingTagList}
		</div>
	);

};

export default ListTags;