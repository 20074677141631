import * as actionTypes from './../actions/actionTypes';

export const searchInitialState = {
			searchLoading: false,
			searchData: null,
			searchError:null,
			searchRedirect: null,

			searchAutocompleteLoading: false,
			searchAutocompleteData: null,
			searchAutocompleteError:null,
			
			searchPagi:{},

			searchQuery: {
                query:null,
                includeIngredients:null,
                titleMatch:null,
				offset:0,
                number:100,
                fillIngredients:true,
				sort:"max-used-ingredients",
				// sort:"min-missing-ingredients",
                instructionsRequired:true,
                // addRecipeInformation:true,
                limitLicense:false,
                ignorePantry:false,
			},
			searchType:null,
			searchInput:null,
			searchFilter:{},
		};

const searchReducer = (state = searchInitialState, action)=>{
	switch(action.type){
		case actionTypes.SEARCH_START: return{ ...state, ...action}; 
		case actionTypes.SEARCH_SUCCESS: return{ ...state, ...action}; 
		case actionTypes.SEARCH_FAIL: return{ ...state, ...action}; 
		case actionTypes.SEARCH_SETREDIRECT: return{ ...state, ...action}; 
		case actionTypes.SEARCH_SETPAGI: return{ ...state, ...action}; 
		case actionTypes.RESET: return{ ...state, ...action};
		case actionTypes.SEARCH_SETQUERY: return{
			...state, 
			// merge with existing
			searchQuery:{ ...state.searchQuery ,...action.searchQuery}
		}; 
		case actionTypes.SEARCH_SETFILTER: return{
			...state, 
			// overwrite existing
			searchFilter:{...action.searchFilter}
		}; 

		case actionTypes.SEARCH_AUTOCOMPLETE_START: return{ ...state, ...action}; 
		case actionTypes.SEARCH_AUTOCOMPLETE_SUCCESS: return{ ...state, ...action}; 
		case actionTypes.SEARCH_AUTOCOMPLETE_FAIL: return{ ...state, ...action};

		default: return state;
	}
};

export default searchReducer;