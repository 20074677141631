import React from 'react';
import { Route } from 'react-router-dom';
import {connect} from 'react-redux';
import {usePrevious} from './../Hooks/Hooks';
import { FaCircle  } from 'react-icons/fa';

import * as actions from './../store/actions/actions';

import classes from './Recipes.module.css';
import SearchForm from './../components/Search/SearchForm/SearchForm';
import Filters from './../components/Filters/Filters';
import ListRecipes from './../components/ListRecipes/ListRecipes';
import RecipeSingle from './../components/RecipeSingle/RecipeSingle';
import Loading from './../components/UI/Loading';
import NotFound from './../components/NotFound/NotFound';

const Recipes = (props)=> {

    const { searchSubmitHandler, searchData, searchQuery, pantryData } = props;

    /*
    * FILTER
    */
        // FILTER RESET
        // reset when a new searchQuery
        let currentQuery;
        const prevQuery = usePrevious( currentQuery );  // setPrev
        const filterForm = React.useRef();  // ref to form
        ['query','includeIngredients'].forEach( key=>{
            if(props.searchQuery[key]){
                currentQuery = props.searchQuery[key];
            }
        });    
        React.useEffect(()=>{
            // match prev and current searchQuery
            // prevQuery !== currentQuery
            // we have a new search submit
            if( prevQuery !== currentQuery ){
                filterForm.current.reset();         // reset form
                setFilterQuery({});                 // reset filterQuery
                //setResetFilter(currentQuery);     // update reset state   
            }
        },[currentQuery, prevQuery, searchQuery]);  // must update when searchQuery changed


        // FILTER QUERY
        const [filterQuery, setFilterQuery] = React.useState({});     
        // get prev state
        const prevFilterQuery = usePrevious(filterQuery);   
        
        // FILTER ONCHANGE HANDLER
        const onChangeHandler = (event,value, groupid)=>{
            let param=[]; 
            let groupValues = filterQuery.hasOwnProperty(groupid) ? [...filterQuery[groupid]] : [];
            let index = groupValues.indexOf(value);
            // is unchecked?
            if(index !== -1){
                groupValues.splice(index,1);
            }else{
                groupValues.push(value);
            }
            param=groupValues;        
            setFilterQuery({...filterQuery, [groupid]:param});

            let loadingIndicator = document.getElementById(`${event.target.id}-loadicon`);
            loadingIndicator.classList.add('current-select');
            // remove
            setTimeout(()=>{
                loadingIndicator.classList.remove('current-select');
            },1800);
        }
        
        // UPDATE RESULTS
        React.useEffect(()=>{
            // only update if filter values changed
            // and have search results
            if( searchData && Object.keys(filterQuery).length && prevFilterQuery !== filterQuery){ 
                // fast selection buffer
                const selectionDelayTimer = setTimeout(()=>{    
                        // add selected params
                        const params = {...filterQuery};
                        // de-structure array values to string
                        Object.keys(params).forEach((key)=>{
                            params[key] = params[key].join(','); 
                        });
                        searchSubmitHandler('/recipe', params, false );
                }, 1800);
                // cleanup
                return ()=>clearTimeout(selectionDelayTimer);
            }          
        },[filterQuery, searchSubmitHandler, prevFilterQuery, searchData])


    /*
    * RECIPE LIST
    */

        /*
        * RESULT LIST
        * we fetch 100 records at a time, display as 10(recipes per page) x 10(pages per pagigroup) 
        * - assign 
        * - we match user's pantry and rearrange results (100)
        * - get saved fetch from session
        * - loop, match pantry, update and sort per max-ings     
        * - break into 10 sets     
        */

            // get saved fetch
            const perPage = 10;
            const initialFetchedData = {
                    number:perPage,
                    totalResults:0,
                    offset:0,
                    results:[],
                };
            const [currentResultSet, setCurrentResultSet ] = React.useState(initialFetchedData);    
            // searchQuery.minIng - we add homepage selection to searchQuery
            const [ingFilter, setIngFilter ] = React.useState(searchQuery.minIng);  


            // break recipes into 10sets
            // start == current pagi-page
            // end == start + per page
            // we always save 100 at a time - results:[0:{}(10),1:{}(20),...,100:{}(100)]
            const setCurrentResultSetHandler = React.useCallback((pagiPage, reset)=>{ 
                // match pagi-link to searchData 100 sets 
                // pagiPage # needs to converted
                // since we only fetch 100 at a time - results:[0:{},1:{},2:{}...]
                // so pagi-pagiPage # is always between 0 - 9
                // ---------
                // *****searchPagi offset start @ 0 
                let recipePage = pagiPage-1;    
                // ---------
                // output current 10 recipes from 100
                // const currentPagiGroup = searchData.results;
                const resultsSetStart  = recipePage*perPage;
                const resultsSetEnd    = resultsSetStart+perPage;
                const results          = [];
                let   totalRecipes     = 0;
                searchData.results.forEach((recipe, key)=>{

                    // apply ing filter
                    // skip anything less than ingFilter
                    let usedIngs = recipe.usedIngredientCount;
                    let totalIngs = usedIngs + recipe.missedIngredientCount;
                    let matchPercentage = ((usedIngs/totalIngs) * 100).toFixed(2)
                    if( matchPercentage < ingFilter ){
                        // skip
                        return;
                    }

                    // count after ingFilter
                    // use counter as key to match recipe
                    totalRecipes++;

                    // get n results
                    if( totalRecipes > resultsSetStart && totalRecipes <= resultsSetEnd ){
                        results.push(searchData.results[key]);
                    }
                });

                // update current results
                setCurrentResultSet( {
                    pagiPage: pagiPage, // mark current pagi page selection
                    results: results,
                    reset: reset,
                    totalResults: totalRecipes,
                } );
            },[searchData, ingFilter ]);

    /*
    * WHEN NEW API FETCH
    * set current result set
    */
    React.useEffect(()=>{
        if( searchData && Object.keys(searchData).length ){
            setCurrentResultSetHandler(1, false);
        }
    },[searchData, setCurrentResultSetHandler, pantryData]);    // pantryData to update when pantry changes
        

    /*
    * PAGINATION HANDLERS
    */
        /*
        * PAGI ONCLICK HANDLER
        */
        const pagiLinkHandler = (pagiPage)=>{
            // set current result set
            setCurrentResultSetHandler(pagiPage, false);
        }


    /*
     * FILTER ING HANDLER
    */
        // FILTER ING HANDLER
        const ingFilterHandler = (value)=>{
            setTimeout(()=>{
                setIngFilter(value);
                // setCurrentResultSetHandler(1, true);
            },500);
        }

    return (
    	<React.Fragment>
            <section ref={props.recipeRef} className={`${props.aniClasses} ${classes.Recipes}`}>
    			<SearchForm     className={classes.Search} 
                                ingFilterHandler={ingFilterHandler}
                            />
                
    		    <section className={classes.ContentArea}>
                    <Filters    onChangeHandler={onChangeHandler} 
                                filterForm={filterForm}
                                resultLoading={props.searchLoading}
                                searchData={searchData}
                            />
        			<article    className="recipeListWrapper" 
                                style={ props.searchLoading ? { minHeight:'75vh', position:'relative' } : {} } // align loading icon
                            >
                        {
                            // loading
                            props.searchLoading && <Loading />
                        }
                        {
                            // error
                            props.searchError && (
                                                    <NotFound   title="Error!!!, a recipe of mess..."
                                                                subTitle={`${props.searchError} and cannot complete this task. please try the following...`} 
                                                                isError={true}
                                                        >   
                                                            <FaCircle />check your internet connection, 
                                                            <FaCircle />try refreshing your browser, 
                                                            <FaCircle />try back in few hours,  
                                                            <FaCircle />and if error still exists please contact us.
                                                    </NotFound>
                                                )
                        }
                        {
                            // no results found
                            !(props.searchLoading || props.searchError) && currentResultSet && currentResultSet.results.length === 0 && (
                                                    <NotFound   title="It's not on the menu..."
                                                                subTitle="If you have already done a search and no recipes were found, try..."
                                                        >   
                                                        <React.Fragment>
                                                            <FaCircle />adjusting or resetting filters,
                                                            <FaCircle />lowering ingredient match,
                                                            <FaCircle />checking your spelling,
                                                            <FaCircle />use more general terms or a different search query
                                                        </React.Fragment>
                                                    </NotFound>
                                                )
                        }
                        {
                            // results
                            !props.searchLoading && currentResultSet.results && currentResultSet.results.length > 0 && (
                                                    <ListRecipes    totalResults={currentResultSet.totalResults} 
                                                                    perPage={perPage}
                                                                    pagiPage={currentResultSet.pagiPage}
                                                                    pagiLinkHandler={pagiLinkHandler}
                                                                    results={currentResultSet.results} 
                                                                    //resultLoading={props.searchLoading} 
                                                                    //resultError={props.searchError} 
                                                                    page='search'
                                                                />
                                                )
                        }
                    </article>
                </section>
    		</section>
            <Route  path='/recipe/:recipeId' exact 
                    render={ props => {
                        return <RecipeSingle {...props} />;
                    }} 
                />

        </React.Fragment>
    );


};


const mapStateToProps = state =>{
    return {
            // using searchData from session
            // will reset searchData everytime and trigger reredner
            // use only for development 
            // searchData: JSON.parse(sessionStorage.getItem('searchData')),
        searchData: state.searchReducer.searchData,
        searchLoading: state.searchReducer.searchLoading,
        searchError: state.searchReducer.searchError,
        searchQuery: state.searchReducer.searchQuery,
        
        pantryData: state.pantryReducer.pantryData,

    }
}
const mapDispatchToProps = dispatch =>{
    return {
        searchSubmitHandler: (redirectLink, querySearch, saveQuery) => dispatch( actions.search_submit(redirectLink, querySearch, saveQuery) ),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Recipes);