import React from 'react';
import {useHistory} from 'react-router-dom';

import { compose } from 'redux';
import { connect} from 'react-redux';
import * as actions from './../store/actions/actions';

// import Modal from './../components/Modal/Modal';
import classes from './withModal.module.css';
import PageTitle from './../components/UI/PageTitle';

export const withModal = (WrapperComponent, modalDefaults)=> {
	return (props) => {
		const history = useHistory();
		// get default redirect if available,
		// otherwise set to current location
		const [currentPath, setCurrentPath] = React.useState(history.location.pathname);
		// set currentPath to modalDefault.redrect 
		React.useEffect(()=>{
			if(modalDefaults.redirect){
				setCurrentPath(modalDefaults.redirect);
			}
		},[setCurrentPath]);	// modalDefaults destruction doesn't refresh
		

		// set default display status
		// otherwise always true
		const currentDisplay = modalDefaults.display ? modalDefaults.display : true;
		// show/hide modal 
		const [showModal, setShowModal] = React.useState(currentDisplay);
		
		// if has error, re-display
		React.useEffect(()=>{
			if(props.authError){
				setShowModal(true);
			}
		},[props.authError]);

		
		// use current path as redirect
		// when modal close, everyting stays the same
		const closeModalHandler = (redirect=currentPath)=>{	
			// must redirect
			// to refresh current-page
			// props.authSetRedirectHandler(redirect);
			history.replace(redirect);
			setShowModal(false);
		}

		// scroll to top
		React.useEffect(()=>{
			// scroll to top
            showModal && window.scrollTo(0, 0);
		},[showModal, history]);

		// return null if closed - showModal null
		// do NOT output anything
		if(!showModal){
			return null;
		}

		return (
			<React.Fragment>
				<div 	id="modal" 
						data-testid="modal"
						style={{
								top:`${modalDefaults.top}`,
								transform: `${modalDefaults.transform}`,
								width:`${modalDefaults.width}`,
								backgroundImage:`url('${modalDefaults.bgImg}')`,
							}} 
						className={classes.Modal} 
					>
					
					{/* HEADER */}
					<div className={classes.Header}>
						{modalDefaults.title && <PageTitle>{modalDefaults.title}</PageTitle>}
						<span 	className={classes.BtnClose}
								data-testid="close-btn"
								onClick={()=>closeModalHandler()}
							></span>
					</div>

					{/* BODY */}
					<div className={classes.Body}
						 data-testid="modal-body"
						>
						<WrapperComponent 	currentPath={currentPath}
											history={history} 
											closeModalHandler={closeModalHandler} 
											{...props} 
						/>
					</div>
				</div>

				<div 	className={classes.Overlay} 
						id="modal-overlay"
						data-testid="modal-overlay"
						onClick={()=>closeModalHandler()}
						></div>

			</React.Fragment>
		);
	};
};

const mapStateToProps = state => {
	return {
		authLoading: state.authReducer.authLoading,
		authError: state.authReducer.authError,
		authData: state.authReducer.authData,
		authRedirect: state.authReducer.authRedirect,
		authResetPasswordSuccess: state.authReducer.authResetPasswordSuccess,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		authSubmitHandler: (values, loginType, redirectLink) => dispatch( actions.auth_submit(values,loginType, redirectLink) ),		
		authSubmitResetPWHandler: (values, loginType, redirectLink) => dispatch( actions.auth_submit_resetpassword(values,loginType, redirectLink) ),		
		authSetRedirectHandler: (link) => dispatch( actions.auth_setredirect(link) ),		
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withModal);