import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import MainMenuList from './../MenuList';

import * as actions from './../../../store/actions/actions';
import classes from './FooterMenu.module.css';

const FooterMenu = (props)=> {

	const initMenuItems = [
		{label: "Home", link : "/"},
		{label: "Search Recipes", link : "/"},
		{label: "Pantry", link : "/pantry"},
		{label: "Favorites", link : "/favorites"},
	];
	
	if(props.authData){
		initMenuItems.push(
			{label: "Account", link : "/account"},
			{label: "Logout", link : "/logout", action:props.authLogoutHandler},
		);
	}else{
		initMenuItems.push(
			{label: "Login", link :`${props.location.pathname}?prompt=login`, action:null },
			{label: "Signup", link :`${props.location.pathname}?prompt=signup`, action:null },
		);
	}

	return (
		<nav className={classes.Nav}>
			<MainMenuList menuItems={initMenuItems} menuType="footer" />
		</nav>
	);
};

const mapStateToProps = state => {
	return {
		authData: state.authReducer.authData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		authLogoutHandler: () => dispatch( actions.auth_logout() ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FooterMenu));