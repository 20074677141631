import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { FaClipboardList, FaCircle } from 'react-icons/fa';

import PredefinedIngJson from './../matchandcook_predefined_inglist.json';
import * as actions from './../store/actions/actions';

import classes from './Pantry.module.css';
import PageTitle from './../components/UI/PageTitle';
import PantryAddIngs from './../components/Pantry/PantryAddIngs';
import PantryIngTable from './../components/Pantry/PantryIngTable';
import PantryPredefinedIngList from './../components/Pantry/PantryPredefinedIngList';
import Tooltip from '@material-ui/core/Tooltip';
import NotFound from './../components/NotFound/NotFound';
import Loading from './../components/UI/Loading';
import Alert from './../components/Alert/Alert';

const Pantry = (props)=> {

    
    // prompt IF NOT LOGIN
    const history = useHistory();
    React.useEffect(()=>{
        if(!props.authData){
            history.replace('/?prompt=login')
        }
    },[props.authData, history]);


    // custom loading - isAddPredefineLoading
    const [isAddPredefineLoading, setIsAddPredefineLoading ] = React.useState(false);
    React.useEffect(()=>{
        if(!props.pantryLoading && !props.pantryLoadingUpdate){
            setIsAddPredefineLoading(false);
        }
    },[props.pantryLoading, props.pantryLoadingUpdate])
    // is match update error
    const [isMatchUpdateError, setIsMatchUpdateError ] = React.useState(false);
    // is delete/cart event
    const [isCartDeleteEvent, setIsCartDeleteEvent ] = React.useState(false);

    // has update/match errors
    // retest hasUpdateMatchErrors here
    // to avoid reload table
    React.useEffect(()=>{
        // skip cart/delete event update/match errors
        // we handle these at table level
        if(!isCartDeleteEvent && !isMatchUpdateError){
            props.pantryErrorMatch && setIsMatchUpdateError(props.pantryErrorMatch);
            props.pantryErrorUpdate && setIsMatchUpdateError(props.pantryErrorUpdate);
            return ()=> setIsMatchUpdateError(false);
        }
    },[props.pantryErrorMatch, props.pantryErrorUpdate, isCartDeleteEvent])
    // reset isCartDeleteEvent
    // only during update tasks, not match/fetch
    React.useEffect(()=>{
        if(isCartDeleteEvent && props.pantryActionType === 'update'){
            return ()=> setIsCartDeleteEvent(false);
        }
    },[props.pantryActionType, isCartDeleteEvent])

    

    
    // DATA ARRAY SETUP
    // set up dataArry from pantryData
    let resultsArray = []; 
    if( props.pantryData && Object.keys(props.pantryData).length ){
        // sort pantryData Obj
        // 01. convert to arry to use .sort(fn(a,b))
        resultsArray = Object.keys(props.pantryData).map(key=> props.pantryData[key]);
        // 02. sort array by label
        resultsArray.sort(function(a, b) {
            return (a.label >= b.label) ? 1 : -1;
        });
    }

/*
* ADD TO CART/DELETE ACTIONS
*/

    // ADD TO SHOP HANDLER
    // set/unset fav add to shop
    const addToShopHandler = (event, ingId)=>{
        const cartStatus = event.target.checked;
        // mark as cart-event
        setIsCartDeleteEvent(true);
        // send patch request
        props.pantryUpdateHandler(props.authData.localId, { [`${ingId}/cart`]: cartStatus}, 'patch');
    }

    // DELETE
    const deleteIngHandler = (event, ingId)=>{
        // mark as delete event
        setIsCartDeleteEvent(true);
        // send delete request
        props.pantryUpdateHandler(props.authData.localId, ingId, 'delete');
    };

/*
* PREDEFINEDLIST
*/
    // PREDEFIENED LIST ADD HANDLER
    const addPredefinedIngHandler = (selectedIdList, action) => {
        // mark as none cart/delete event
        setIsCartDeleteEvent(false);
        // mark as predifined loading
        setIsAddPredefineLoading(true);
        let preDefinedList = [];
        PredefinedIngJson.forEach(list=>{
            selectedIdList.forEach(itemId=>{
                if(itemId in list){
                    preDefinedList.push(...list[itemId]);
                }
            })
        });
        // match and update pantry
        props.pantryMatchIngsAndUpdate(preDefinedList, action);
    }

    // SHOW/HIDE PREDEFINEDLIST MODAL
    const [displayPredefinedList, setDisplayPredefinedList ] = React.useState(false);
    // reset display
    React.useEffect(()=>{
        // reset display at new render
        if(displayPredefinedList){
            return ()=>setDisplayPredefinedList(false);
        }
    });

    // set display true if pantryData missing
    React.useEffect(()=>{
        // update display status when pantryData
        if(!props.pantryData){
            setDisplayPredefinedList(true);
        }
    },[props.pantryData]);


	return (
		<section className={classes.Pantry}>
			<PageTitle>Manage Your <span>Pantry</span></PageTitle>
                {
                    // show adding bar if there's not fetch errors
                    !props.pantryError 
                        &&  (
                                <div className={classes.AddIngWrapper}>
                                    <PantryAddIngs isMatchUpdateError={isMatchUpdateError} />
                                    <span className={classes.Or} > OR </span>
                                    <Tooltip title="Select from Predefined Lists" placement="top" arrow>
                                        <span>
                                            <FaClipboardList className={classes.List} onClick={()=>setDisplayPredefinedList(true)} />
                                        </span>
                                    </Tooltip>
                                </div>
                            )
                }
                {
                    // loading
                    isAddPredefineLoading && <Loading />
                }
                { 
                    // update/match error
                    (props.pantryData && !props.pantryLoading && isMatchUpdateError && !isCartDeleteEvent)
                        && <Alert   message={`${isMatchUpdateError} and the update did not work. Please try again`} 
                                    type="error" 
                                    reset={props.pantryErrorUpdate ? 'pantryErrorUpdate' : 'pantryErrorMatch'} 
                                /> 
                }
                {
                    // fetch error
                    props.pantryError && (
                                            <NotFound   title="Error!!!, a recipe of mess..."
                                                        subTitle={`${props.pantryError} and cannot complete this task. please try the following...`} 
                                                        isError={true}
                                                >   
                                                    <FaCircle />check your internet connection, 
                                                    <FaCircle />try refreshing your browser, 
                                                    <FaCircle />try back in few hours,  
                                                    <FaCircle />and if error still exists please contact us.
                                            </NotFound>
                                        )
                }
                {
                    // no results found
                    !isAddPredefineLoading && !props.pantryError && !isMatchUpdateError && resultsArray.length === 0 && (
                                            <NotFound   title="Ops! this pantry is empty..."
                                                        subTitle="Looks like you haven't setup your pantry yet! Please add ingredients..." 
                                                >   
                                                    <FaCircle />by using search bar above, OR
                                                    <FaCircle />by clicking the icon next to searchbar and selecting from the pre-defined lists.
                                            </NotFound>
                                        )
                }
                {
                    // table output
                    // we output table even there's update/match errors
                    // but not fetch errors
                    !isAddPredefineLoading && !props.pantryError && resultsArray.length > 0 && (
                                            <PantryIngTable     addToShopHandler={addToShopHandler} 
                                                                deleteIngHandler={deleteIngHandler}
                                                                resultsArray={resultsArray} 
                                                                //isMatchUpdateError={isMatchUpdateError}
                                                        />
                                        )
                }
                


                {
                    // predefined list
                    displayPredefinedList
                        && <PantryPredefinedIngList addPredefinedIngHandler={addPredefinedIngHandler} />
		        }

        </section>
	);
};

const mapStateToProps = state =>{
    return {
        authData: state.authReducer.authData,       
        pantryData: state.pantryReducer.pantryData,     
        pantryLoading: state.pantryReducer.pantryLoading,     
        pantryLoadingUpdate: state.pantryReducer.pantryLoadingUpdate,     
        
        pantryActionType: state.pantryReducer.pantryActionType,     
        
        pantryError: state.pantryReducer.pantryError,     
        pantryErrorUpdate: state.pantryReducer.pantryErrorUpdate,     
        pantryErrorMatch: state.pantryReducer.pantryErrorMatch,     
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        pantryUpdateHandler: (user, Data, updateType) => dispatch( actions.pantry_update(user, Data, updateType) ),
        pantryMatchIngsAndUpdate: (query, action) => dispatch( actions.pantry_matchIngsToQuery(query, action) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pantry);