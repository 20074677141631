import * as actionTypes from './actionTypes';
import * as actions from './actions';
import axios from 'axios';

// start
export const auth_start = ()=>{
	return{
		type: actionTypes.AUTH_START,
		authError: null,	// reset
		authLoading: true,
	}
}

// success
export const auth_success = (data, redirectLink)=>{
	return{
		type: actionTypes.AUTH_SUCCESS,
		authError: null,
		authLoading: false,
		authData: data,
		authRedirect: redirectLink,
	}
}

// reset password success
export const auth_success_resetpassword = (data, redirectLink)=>{
	return{
		type: actionTypes.AUTH_SUCCESS_RESETPASSWORD,
		authLoading: false,
		authResetPasswordSuccess: data,
		authError: null,
		authRedirect: redirectLink,
	}
}

// fail
export const auth_fail = (error, redirectLink)=>{
	return{
		type: actionTypes.AUTH_FAIL,
		authError: error,
		authLoading: false,
		authData: null,
		authRedirect: redirectLink,
	}
}

// logout
export const auth_logout = ()=>{
	console.log('**logout');
	localStorage.removeItem('userAuth');	// removed saved data
	actions.pantry_fetch(null);				// reset pantry
	return{
		type: actionTypes.AUTH_LOGOUT,
		authError: null,
		authLoading: null,
		authData: null,
		authRedirect: '/',
	}
}

// reauth
export const auth_check = (token)=>{
	return dispacth => {
		dispacth( auth_submit(token, 'reauth') );
	}
}


		/*
		* HELPER ACTIONS
		*/
		
		// set redirect link
		// loginsubmit success and fail 
		// handles redirects within
		export const auth_setredirect = (link)=>{
			return{
				type: actionTypes.AUTH_SETREDIRECT,
				authRedirect: link,
			}
		}

		// reset
		export const reset = (resetProp)=>{
			return{
				type: actionTypes.RESET,
				[resetProp]: null,
			}
		}

		// timeout
		export const auth_timeout = (time)=>{
			return dispacth => {
				setTimeout(()=>{
					dispacth( auth_logout() );
				}, time*1000 );
			}
		}


// async submit
export const auth_submit = (values, authType, redirectLink='/')=>{
	return (dispacth, getState ) => {
		// start
		dispacth( auth_start() );
		
		// setup request headers
		let key       = process.env.REACT_APP_FB_API;
		let fbauthurl = process.env.REACT_APP_FB_API_AUTH_URL;
		let type;
		let requestBody;

		switch(authType){
			case 'login': 
				type = 'signInWithPassword'; 
				requestBody = {
								email: values.user_email,
								password: values.user_password,
								returnSecureToken: true,
							};
				break;
			case 'signup':
				type = 'signUp'; 
				requestBody = {
								displayName: values.user_name,
								email: values.user_email,
								password: values.user_password,
								returnSecureToken: true,
							};
				break;
			case 'reauth':
				type = 'lookup'; 
				requestBody = {
								idToken: values,
								returnSecureToken: true,
							}
				break;
			default			:  break;
		}

		let url  = fbauthurl+'/accounts:'+type+'?key='+key;
		
		// request
		axios.post(url, requestBody)
		.then(response=>{
			console.log('***auth:', authType);
			// set localstorage
				// results = switch access points per request type 
			let results = (authType === 'reauth')? response.data.users[0] : response.data;
			const storeData = {
				displayName: results.displayName,
				email: results.email,
				// idToken lookup doesn't return idToken
				// reuse same param value
				idToken: (authType === 'reauth') ? values : results.idToken,
				localId: results.localId,				
			};
			// save to localstorage
			localStorage.setItem('userAuth', JSON.stringify(storeData));
			// set logout timeout in secounds
			// always 3hrs from last visit
			dispacth( auth_timeout(3600) );

			// dispatch success
			// all authentication requests are
			// always handled here 
			// send signup success to pantry directly
			if( authType === 'signup'){
				redirectLink = '/pantry';
			} 
			dispacth( auth_success(storeData, redirectLink) );

			// fetch and update user pantry, favorites 
			dispacth( actions.pantry_fetch(results.localId) );
			dispacth( actions.favorite_fetch(results.localId) );
			
		})
		.catch(error=>{
			//add authType to error obj
			error[type]= authType;

			if( authType === 'reauth'){
				console.log('**reauth error:');
				// do not output reauth fails
				// logout user to remove token and prompt intro
				dispacth( auth_logout() );	
				// defautl redirect '/'
			}else{
				console.log('**auth error:');
				// mark error and redirect back to logintype
				// all authentication errors 
				// needed to be output on modal
				dispacth( auth_fail(error.response.data.error, redirectLink+'?prompt='+authType) ); 
				// dispacth( auth_setredirect(redirectLink+'?prompt='+authType) );
			}
		});
		
	}
}

// async resetpassword
// async submit
export const auth_submit_resetpassword = (values, authType, redirectLink='/')=>{
	return dispacth => {
		// start
		dispacth( auth_start() );
		
		// setup request headers
		let key  =process.env.REACT_APP_FB_API;
		let fbauthurl = process.env.REACT_APP_FB_API_AUTH_URL;
		let type;
		let requestBody;

		switch(authType){
			case 'resetpasswordlink': 
				type = 'sendOobCode'; 
				requestBody = {
								requestType: 'PASSWORD_RESET',
								email: values.user_email,
							};
				break;
			case 'resetpassword':
				type = 'resetPassword'; 
				requestBody = {
								oobCode: values.oobcode,
								newPassword: values.new_password,
							};
				break;
			default			:  break;
		}

		let url  = fbauthurl+'/accounts:'+type+'?key='+key;
		
		// request
		axios.post(url, requestBody)
		.then(response=>{
			console.log('***auth:', authType);
			// dispatch resetpassword success message
			// redirect back to same modal screen
			dispacth( auth_success_resetpassword(response.data, redirectLink+'?prompt='+authType) );
		})
		.catch(error=>{
			console.log('**auth error:');
			// mark error and redirect back to logintype
			dispacth( auth_fail(error.response.data.error, redirectLink+'?prompt='+authType) ); 
		});
	}

}