import * as actionTypes from './actionTypes.js';
import axios from 'axios';

// start
export const favorite_start = (type)=>{
	return{
		type: actionTypes.PANTRY_START,
		favoriteError: null,	// reset
		favoriteErrorUpdate: null,
		favoriteErrorMatch: null,

		favoriteLoading: type==='fetch' ? true : false,
		favoriteLoadingUpdate: type==='update' ? true : false,
		favoriteLoadingMatch: type==='match' ? true : false,
	}
}

// success
export const favorite_success = (data)=>{
	return{
		type: actionTypes.PANTRY_SUCCESS,
		favoriteError: null,
		favoriteErrorUpdate: null,
		favoriteErrorMatch: null,

		favoriteLoading: false,
		favoriteLoadingUpdate: false,
		favoriteLoadingMatch: false,
		
		favoriteData: data,
	}
}

// fail
// fetch - favoriteError 
// update - favoriteErrorUpdate 
// match - favoriteErrorMatch 
export const favorite_fail = (error, type)=>{
	return{
		type: actionTypes.PANTRY_FAIL,
		favoriteError: type === 'fetch' ? error : null,
		favoriteErrorUpdate: type === 'update' ? error : null,
		favoriteErrorMatch: type === 'match' ? error : null,
		
		favoriteLoading: false,
		favoriteLoadingUpdate: false,
		favoriteLoadingMatch: false,
		
		//favoriteData: null,	// not using to preserve fetch data if update error
	}
}

	/*
	* HELPER ACTIONS
	*/

	// reset
	export const reset = (resetProp)=>{
		return{
			type: actionTypes.RESET,
			[resetProp]: null,
		}
	}


// async FB - fetch favorite items
export const favorite_fetch = (user, action='get')=>{
	return (dispacth, getState) => {
		// start
		dispacth( favorite_start('fetch') );
		
		// setup request headers
		// let key  =process.env.REACT_APP_FB_API;
		let fburl   = process.env.REACT_APP_FB_API_URL;
		let userObj = getState().authReducer.authData;
		let idToken = userObj && userObj.idToken;
		let url     = fburl+'/favorite/'+user+'.json?auth='+idToken;
		
		// request
		axios.get(url)
		.then(response=>{
			dispacth( favorite_success(response.data) );
		})
		.catch(error=>{
			console.log('**favorite fetch error:');
			dispacth( favorite_fail(error.response.data.error, 'fetch') );
		});
		
	}
}

// async FB - update favorite items
export const favorite_update = (user, data, action='patch')=>{
	return (dispacth, getState) => {
		// start
		dispacth( favorite_start('update') );
		
		// setup request headers
		// let key  =process.env.REACT_APP_FB_API;
		let fburl   = process.env.REACT_APP_FB_API_URL;
		let userObj = getState().authReducer.authData;
		let idToken = userObj && userObj.idToken;
		let url     = fburl+'/favorite/'+user+'.json?auth='+idToken;
		let body    = data;


		// request
		let resultsPromise;
		switch(action){
			case 'delete' 	: 
				// adjust url
				url  = fburl+'/favorite/'+user+'/'+data+'.json?auth='+idToken;
				resultsPromise = axios.delete(url); break;
			// default is patch
			default 		: resultsPromise = axios.patch(url, body); break;
		}
		
		resultsPromise.then(response=>{
			// trigger re-fetch to get updated favoriteData
			dispacth( favorite_fetch(user) );
		})
		.catch(error=>{
			console.log('**favorite update error:');
			dispacth( favorite_fail(error.response.data.error, 'update') );
		});
		
	}
}

// ASYNC SP get match recipes
// MATCH RECIPIES IN THE QUERY and UPDATE FAVOURTIE 
export const favorite_matchRecipeToQuery = (query)=>{
	return (dispacth, getState) => {
        
        // start
		dispacth( favorite_start('match') );
		
		// match and update
        // match query to ings with SP
        // update pantry list with FB
        const gcfUrl      = process.env.REACT_APP_GCF_URL;
        const gcfEndpoint = 'matchrecipes'; 
        const param       = '?recipeids='+query.join(','); 

        // request
        axios.get(gcfUrl+gcfEndpoint+param)
        .then(response=>{
	        // setup update params
	        const favoriteUpdateBody = {};
	        response.data.forEach( recipe => {
	        	// manually mark missed, used ings
	        	// since this endpoint does retrieve these proporties
        		// current pantry
        		const pantryData = getState().pantryReducer.pantryData;
        		let missedIngredients = [];
        		let usedIngredients = [];
	        	recipe.extendedIngredients.forEach(ing=>{
        			if( pantryData && ing.id in pantryData){
	        			usedIngredients.push(ing);		
	        		}else{
	        			missedIngredients.push(ing);		
	        		}
	        	});
        		// add missing properties
				recipe["missedIngredients"]     = missedIngredients;
				recipe["missedIngredientCount"] = missedIngredients ? missedIngredients.length : 0;
				recipe["usedIngredients"]       = usedIngredients;
				recipe["usedIngredientCount"]   = usedIngredients ? usedIngredients.length : 0;

	            // setup param
	            favoriteUpdateBody[recipe.id] = recipe; 
	        })
            // setup update type with user
            dispacth( favorite_update( getState().authReducer.authData.localId, favoriteUpdateBody, 'patch') );
        })
        .catch(error=>{
            console.log('**fav-recipe-match error:');
            // match gcf error message
            dispacth( favorite_fail(error.response ? error.response.data.error : error, 'match') );
        });	
	}
}