import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import * as actions from './../../store/actions/actions';

import classes from './Favorites.module.css';
import { FaHeart, FaCircleNotch } from 'react-icons/fa';

const AddToFav = (props)=> {

	const history = useHistory();
	const {favoriteData, recipeId, authData} = props;
	// loading
	const {favoriteLoadingUpdate, favoriteLoadingMatch } = props;
    const [isLoading, setIsLoading] = React.useState(false);
	// set current functioning id
	const [current, setCurrent] = React.useState(null);
	// is error
    const {favoriteErrorUpdate, favoriteErrorMatch, resetHandler } = props;
    const [errorMessage, setErrorMessage] = React.useState(null);
    // fave status
	const [isInFav, setIsInFav] = React.useState(false);


	// loading
    React.useEffect(()=>{
        if(favoriteLoadingUpdate || favoriteLoadingMatch){
        	setIsLoading(true);
        	// cleanup - reset isAddToFavLoading
			return ()=> setIsLoading(false);
        }
    },[favoriteLoadingUpdate, favoriteLoadingMatch, setIsLoading]);


	// is error
    React.useEffect(()=>{
       if(favoriteErrorUpdate || favoriteErrorMatch){
            setErrorMessage(favoriteErrorUpdate ? favoriteErrorUpdate : favoriteErrorMatch)
            // cleanup
            return ()=> {
                setErrorMessage(null);
                resetHandler(favoriteErrorUpdate ? "favoriteErrorUpdate" : "favoriteErrorMatch")
            };
       }
    },[favoriteErrorUpdate, favoriteErrorMatch, resetHandler, setErrorMessage]);


	// update fav status when loading
	// or favdata change
	React.useEffect(()=>{
		// is already in 
		if(favoriteData && recipeId in favoriteData){
			setIsInFav(true);
		}else{
			// guard
			isInFav && setIsInFav(false);
		}
		return ()=>setCurrent(false);
	},[favoriteData, recipeId, isInFav, setCurrent]);


	// add handler
	const addToFavHandler = (event, recipeId) => {
		event.stopPropagation();	// stop triggering single modal	
		event.preventDefault();		// stop triggering single modal	
		
		// check if login
		// otherwise prompt
		if(authData){
			// set current
			setCurrent(true);
			// mark as loading
			setIsLoading(true);
			
			// is already in fav? remove
			if(isInFav){
				// update FB
				props.favoriteUpdateHandler(authData.localId, recipeId , 'delete');
			}
			// add
			else{
				// fetch full recipe detail, 
				// and update FB
				props.favoriteMatchRecipeToQueryHandler([recipeId]);
			}
		}
		// login prompt
		else{
			// if singleRecipe takeout the recipeId from url
			let redirectPath = history.location.pathname.replace(`/${props.recipeId}`,''); 
			history.replace(redirectPath+'?prompt=login');
		}
	}


return (
	<span 	className={`${classes.AddToFav} ${ isInFav  ? classes.AddToFavActive:''}` }
			onClick={(event)=>addToFavHandler(event, recipeId) }
		>
		{ 
			isLoading && current
			? <FaCircleNotch className="filter-load-indicator fa-spinner" />
			: <FaHeart />
		}
		{ 
			!isLoading && errorMessage && current
				&& <span className="load-error-small">Error</span>
		}
	</span>
);


};


const mapStateToProps = state =>{
    return {
        authData: state.authReducer.authData,
        favoriteData: state.favoriteReducer.favoriteData,
        
        favoriteLoadingUpdate: state.favoriteReducer.favoriteLoadingUpdate,
        favoriteLoadingMatch: state.favoriteReducer.favoriteLoadingMatch,

        favoriteErrorUpdate: state.favoriteReducer.favoriteErrorUpdate,
        favoriteErrorMatch: state.favoriteReducer.favoriteErrorMatch,
    }
}
const mapDispatchToProps = dispatch =>{
    return {
        favoriteUpdateHandler: (user, Data, updateType) => dispatch( actions.favorite_update(user, Data, updateType) ),
        favoriteMatchRecipeToQueryHandler: (query) => dispatch( actions.favorite_matchRecipeToQuery(query) ),
        resetHandler: (property) => dispatch( actions.reset(property) ),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddToFav);