import React from 'react';

const Loading = (props)=> {
	// load rolling-pin.svg as inline base64
	// to avoid any delay
	return (
		<span className="loading-wrapper">
			<img 	alt="loading icon" 
				 	src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni4wNyA5Ljc0Ij48dGl0bGU+cm9sbGluZy1waW48L3RpdGxlPjxwYXRoIGQ9Ik0zNTcuNDMsMjQ1LjQzYTIuOTEsMi45MSwwLDAsMC0xLjg4LS44QTI4LjIzLDI4LjIzLDAsMCwwLDM0OCwyNDVhMy4zOSwzLjM5LDAsMCwxLTMuMzEtMS42MywzLDMsMCwwLDAtMi42NC0xLjVxLTEyLjE4LDAtMjQuMzUsMGEyLjc5LDIuNzksMCwwLDAtMi41MywxLjQ1LDMuMzcsMy4zNywwLDAsMS0zLjQ0LDEuNjcsMjcuOTIsMjcuOTIsMCwwLDAtNy4zNi0uMjljLTEuMjIuMTgtMi4zOC40Ny0yLjQ3LDItLjA3LDEuMTguODMsMS44NywyLjU2LDIuMDlhMzIuMjQsMzIuMjQsMCwwLDAsMy42OC4wOWMyLC4wNSw0LTEuMiw2LC4yNS40LjI5Ljc4LjQzLDEsMWEyLjUsMi41LDAsMCwwLDIuNDUsMS41MmgyNC42NWEyLjY2LDIuNjYsMCwwLDAsMi4zLTEuMzEsMy41NCwzLjU0LDAsMCwxLDMuNjctMS43OSwyNC4wOSwyNC4wOSwwLDAsMCw3LjgxLjE5LDIuMTIsMi4xMiwwLDAsMCwxLjgxLTEuNDdBMS40OSwxLjQ5LDAsMCwwLDM1Ny40MywyNDUuNDNabS00Mi40LDIuMWMwLC4zMSwwLC42NC0uMzQuNzhhLjYzLjYzLDAsMCwxLS44OS0uMzdjLS4yOS0uNy0uODItLjQ0LTEuMy0uMzdhMzcsMzcsMCwwLDEtNy4wNS41NSw1LjcyLDUuNzIsMCwwLDEtMi4wOS0uNDFjLTEtLjQzLS44My0xLjEzLS40OC0xLjg0YTUuNjksNS42OSwwLDAsMSwyLjQ0LS42NCwzNi4wNiwzNi4wNiwwLDAsMSw3LC41MmMuNDguMDcsMSwuNDMsMS4zNi0uMjIuMjItLjQxLjQ3LS43MSwxLS40OHMuMzYuNTkuMzcuOTRDMzE1LjA2LDI0Ni41LDMxNSwyNDcsMzE1LDI0Ny41M1ptMjcuNDIsMy4yNHEtMTIuNDYsMC0yNC45MywwYy0xLjEzLDAtMS43Mi0uNDktMS43MS0xLjY3LDAtMS42NCwwLTMuMjksMC00LjkzLDAtLjU0LDAtMS4zMy43My0xLjI4LjU5LDAsLjI0Ljc3LjM0LDEuMThzLS4xNCw0LjY1LjM5LDQuOTNjLjExLS4xNC4yNC00LjA3LjI2LTQuMTkuMzctMi4yNC4zNi0yLjIzLDIuNjktMi4yM2gyMS40YzIuMjgsMCwyLjQzLjE3LDIuNDMsMi40NiwwLDEuMzQsMCwyLjY3LDAsNEMzNDQuMTEsMjUwLjE4LDM0My42NCwyNTAuNzcsMzQyLjQ1LDI1MC43N1ptMTQuMTUtMy4wOGE1LjA1LDUuMDUsMCwwLDEtMi4wOS40MywzNCwzNCwwLDAsMS02LjI5LS40M2MtLjg0LS4xMi0xLjc5LS42Ni0yLjM5LjU1LS4xMy4yNy0uNTYuMTgtLjgtLjA3YTUuNzIsNS43MiwwLDAsMSwwLTNjLjMyLS4zLjc2LS4zMi45MiwwLC40MiwxLDEuMTMuNiwxLjgxLjVhMzQuNzEsMzQuNzEsMCwwLDEsNi43NS0uNSw0LjY4LDQuNjgsMCwwLDEsMi41Mi42OUMzNTcuNCwyNDYuNjIsMzU3LjQ5LDI0Ny4yNiwzNTYuNiwyNDcuNjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAxLjkzIC0yNDEuODEpIi8+PC9zdmc+" 
				 />
			<span>loading...</span>
		</span>
	);
};

export default Loading;