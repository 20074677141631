import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import axios from 'axios';

import withModal from './../../HOC/withModal';
import classes from './RecipeSingle.module.css';
import bgImg from './bgRecipeSingle.png';
import ListTags from './../ListTags/ListTags';
import ListFacts from './../ListFacts/ListFacts';
import Alert from './../Alert/Alert';
import {FaPagelines, FaClock, FaUtensils} from 'react-icons/fa';
import PageTitle from './../UI/PageTitle';
import Loading from './../UI/Loading';
import CardImg from './../Card/CardImg';

// set modal defaults
const modalDefaults = {
    	title: null,   // we output at body-level
        width:'680px',
        bgImg: bgImg,
        display: true,
        top: '2%',
        transform: 'translate(-50%, 0)',
};

const RecipieSingle = (props)=> {
    
    const history = useHistory();
    // GET SELECTED RECIPE ID
    // we pass id via router match /recipe/45555
    // 
    const [selectRecipeId, SetSelectRecipeId] = React.useState(null);
    React.useEffect(()=>{
        // set modal default display
        modalDefaults['display'] = Boolean(props.match.params.recipeId);
        // get path from path/recipeId
        let pathBeforeId = history.location.pathname.substring(0,history.location.pathname.lastIndexOf("/"));
        modalDefaults['redirect']= pathBeforeId; 
        // set selected Recipe
        SetSelectRecipeId(props.match.params.recipeId);
    },[props.match.params.recipeId, history]);

    // FETCH SINGLE RECIPE DATA
    // we save each fetched singleData to session list
    // only fetch if new request, otherwise retrieve from session 
    const [singleRecipeData, setSingleRecipeData] = React.useState(null);
    const [singleRecipeDataLoading, setSingleRecipeDataLoading] = React.useState(false);
    const [singleRecipeDataError, setSingleRecipeDataError] = React.useState(null);
    // fetch
    React.useEffect(()=>{
        if( selectRecipeId ){
            // indicate loading, reset error
            setSingleRecipeDataLoading(true);
            setSingleRecipeDataError(null);

            // is already fetched
            // we saved each fetched singles into session as [ id: {} ]
            // get save fetched list
            const fetchedSinglesList = JSON.parse(sessionStorage.getItem('searchSingles'));
            if( fetchedSinglesList && selectRecipeId in fetchedSinglesList ){
                // get data from session
                setSingleRecipeDataLoading(false);
                setSingleRecipeData(fetchedSinglesList[selectRecipeId]); 
            }
            // new fetch
            else{                
                const gcfUrl      = process.env.REACT_APP_GCF_URL;
                const gcfEndpoint = 'singlerecipe'; 
                const param       = '?recipeid='+selectRecipeId; 

                axios.get(gcfUrl+gcfEndpoint+param)
                .then(response=>{
                    // save to session
                    sessionStorage.setItem('searchSingles', JSON.stringify({ ...fetchedSinglesList, [response.data.id]:response.data}));
                    // set recipeData
                    setSingleRecipeDataLoading(false);
                    setSingleRecipeData(response.data); 
                })
                .catch(error=>{
                    setSingleRecipeDataLoading(false);
                    setSingleRecipeDataError(error.response.data); 
                });
            }
        }
    },[selectRecipeId]);


    // RECIPE DETAIL PAGE SETUP
    const ingTagList    = [];
    let instructionList = [];
    let factsList       = [];
    let recipeTitle     = null;
	if(singleRecipeData && Object.keys(singleRecipeData).length){
        
        // UPDATE MODAL TITLE
        // add modal title
        let creditText = singleRecipeData ? `--- by ${singleRecipeData.creditsText} ---` : null;
            creditText = (creditText && singleRecipeData.sourceUrl) 
                                ? <a href={singleRecipeData.sourceUrl} target="_blank" rel="noopener noreferrer">{creditText}</a> 
                                : creditText;
        recipeTitle =   (
                            <span className={classes.Title}>
                                {singleRecipeData.title}
                                <span className={classes.Credit} >{creditText}</span>
                            </span>
                        );


        // MATCH INGREDIENTS TO PANTRY
        // match fetched recipe ingredients to pantry
        let avilIngs        = 0;
    	singleRecipeData.extendedIngredients.map(ing=>{
    		let isInPantry = false;
    		// if ing is in pantry
        	if(props.pantryData && Object.keys(props.pantryData).length){
                if(ing.id in props.pantryData){
    				avilIngs++;
    				isInPantry = true;
    			}
            }

    		ingTagList.push( {id:ing.id, label:ing.originalString, status: isInPantry} );
    		return null;
    	});
	    
        // RECIPE FACTS
        // generate recipe facts
        let totalIngs   = singleRecipeData.extendedIngredients.length;
        let ingCount    = avilIngs+"/"+totalIngs;
        let ingCountPer = ((avilIngs/totalIngs)*100).toFixed(0);
    	factsList = [
            {label: "avilIngs", data: `${ingCountPer}% match (${ingCount})`, icon: <FaPagelines /> },
    		{label: "duration", data: singleRecipeData.readyInMinutes+"min", icon: <FaClock /> },
    		{label: "level", data: singleRecipeData.dishTypes.join(', '), icon: <FaUtensils /> },
    	];
    	
        // INSTRUCTIONS
        // generate instruction steps
    	instructionList = [...singleRecipeData.analyzedInstructions[0].steps].map(step=>{
    		return <li key={step.number}><span>Step {step.number} :</span> {step.step}</li>;
    	});
    }


    // HAS ERROR
    // output error data
    if(singleRecipeDataError && Object.keys(singleRecipeDataError).length){
        return <Alert  message={singleRecipeDataError.message} type="error" reset="" />
    }

	return (
		<div className={classes.Wrapper} >
			{
                singleRecipeDataLoading
                ?   <Loading />
                :  (
                        <React.Fragment>
                            <PageTitle>{recipeTitle}</PageTitle>
                            <div className={classes.ContentWrapper} >                				
                                <CardImg    classes={classes.ImgWrapper}
                                            image={singleRecipeData && singleRecipeData.image} 
                                            id={selectRecipeId}
                                            isSinglePage={true} // to modify fav-redirect 
                                        />
                				<div className={classes.InfoWrapper} >
                				    <ListFacts factsList={factsList} />
                					<h2 className={classes.H2}>You need:</h2>
                					<ListTags ingTagList={ingTagList} />
                				</div>
                			</div>
                			<div className={classes.InstructionWrapper} >
                				<h2 className={classes.H2}>Directions:</h2>
                				<ul className={classes.InstructionList} >
                					{instructionList}
                				</ul>
                			</div>
                			<div className={classes.footerWrapper} >
                				{
                                /*  <div style={{textAlign:"center", marginTop:"2em"}} >
                					   <Button type="button" 
                							//action=""
                							//btnClass=""
                							text="Add To Favorite"
                						/>
                				    </div>*/
                                }
                			</div>
                        </React.Fragment>
                    )
            }
		</div>
	);
};

const mapStateToProps = state =>{
    return {
        pantryData: state.pantryReducer.pantryData,
    }
}

export default connect(mapStateToProps, null)(withModal(RecipieSingle, modalDefaults));