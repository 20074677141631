import React from 'react';
import {connect} from 'react-redux';

import classes from './MainMenu.module.css';
import MainMenuList from './../MenuList';
import LoginMenu from './../LoginMenu/LoginMenu';
import { FaHeart, FaCarrot, FaSearch  } from 'react-icons/fa';


const MainMenu = (props)=> {

	const initMenuItems = [
		{label: "Search Recipes", link : "/recipe", icon:<FaSearch/>},
		{label: "Pantry", link : "/pantry", icon:<FaCarrot/>},
		{label: "Favorites", link : "/favorites", icon:<FaHeart/>},
	];

	return (
		<nav className={classes.Nav}>
			<MainMenuList menuItems={initMenuItems} menuType="main" />
			<LoginMenu />
		</nav>
	);
};

const mapStateToProps = state => {
	return {
		authData: state.authReducer.authData,
	}
};

export default connect(mapStateToProps, null)(MainMenu);