import React from 'react';
import qs from 'query-string';

import {Formik} from 'formik';
import * as Yup from 'yup';

import classes from './Login.module.css';
import withModal from './../../HOC/withModal';
import ResetPasswordForm from './ResetPasswordForm';
import bgImg from './bgLogin.png';
import Alert from './../Alert/Alert';
import Button from './../UI/Button';


export const formikConfig = {
		initialValues:{ new_password:'', confirm_password:'', oobcode:''},
		validationSchema:Yup.object({
			                    new_password: Yup.string()
			                    				.min(6, 'Too Short!')
												.max(50, 'Too Long!')
			                    				.required('Required'),
			                    confirm_password: Yup.string()
			                    				.when("new_password", {
													    is: val => (val && val.length > 0 ? true : false),
													    then: Yup.string().oneOf(
													      [Yup.ref("new_password")],
													      "Password did not match"
													    )
												  })
			                    				.required('Required'),
	    				}),
};


const ResetPassword = (props)=> {

	// add obbcode
	const oobcode = qs.parse(props.history.location.search).oobCode;
	formikConfig.initialValues.oobcode = oobcode;

	// output if succss message
	if(props.authResetPasswordSuccess){
		return (
				<React.Fragment>
					<Alert  message="Your password has been reset successfully. Please login with your new password." 
							type="success"
							classes="regular"
							reset="authResetPasswordSuccess" />
					<div 	className={classes.BtnWrapper}
							style={{textAlign:"center", marginTop:"2em"}} >
						<Button action={()=>props.closeModalHandler(`${props.currentPath}?prompt=login`)} 
								type="button"
								text="Login"
							/>
					</div>
				</React.Fragment>
			);
	}

	// output form
	return (
		<Formik {...formikConfig } 
				onSubmit={ (values)=> {
						// modal close will be handled
						// during redirection in auth
						props.authSubmitResetPWHandler(values, 'resetpassword', props.currentPath);
					} }
			>
      			{  
      				(formikProps) => {
      					return (
      						<ResetPasswordForm 	{...formikProps} 
      									currentPath={props.currentPath} 
      									authError={props.authError}
      									/> 
      					);
      				}
      			}	
      	</Formik>
	);

};

// set modal defaults
const modalDefaults = {
	title: <React.Fragment>Password Reset</React.Fragment>, 
	width:'400px',
	bgImg: bgImg,
};



export default withModal(ResetPassword, modalDefaults);