import React from 'react';

import classes from './ListTag.module.css';

const ListTag = (props)=> {

	return (
		<span 	id={props.id} 
				className={`${classes.Tag} ${props.status? classes.TagIncluded : classes.TagNotincluded}`} 
				>
			{props.label}
		</span>
	);
};

export default ListTag;