import React from 'react';

// import { connect } from 'react-redux';
// import * as actions from './../../store/actions/actions';

import {Formik} from 'formik';
import * as Yup from 'yup';

import withModal from './../../HOC/withModal';
import LoginForm from './LoginForm';
import bgImg from './bgLogin.png';



export const formikConfig = {
		initialValues:{ user_email:'', user_password:'' },
		validationSchema:Yup.object({
			                    user_email: Yup.string()
			                    				.email('Invalid email')
			                    				.required('Required'),
			                    user_password: Yup.string()
			                    				.min(6, 'Too Short!')
												.max(50, 'Too Long!')
			                    				.required('Required'),
	    				}),
};


const Login = (props)=> {

	return (
		<Formik {...formikConfig} 
				onSubmit={ (values)=> {
						// modal close will be handled
						// during redirection in auth
						props.authSubmitHandler(values, 'login', props.currentPath);
					} }
			>
      			{  
      				(formikProps) => {
      					return (
      						<LoginForm 	{...formikProps} 
      									currentPath={props.currentPath} 
      									authError={props.authError}
      									/> 
      					);
      				}
      			}	
      	</Formik>

	);
};

// set modal defaults
const modalDefaults = {
	title: <React.Fragment>Login</React.Fragment>, 
	width:'400px',
	bgImg: bgImg,
};



export default withModal(Login, modalDefaults);