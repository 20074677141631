import React from 'react';
import qs from 'query-string';
import {Route, withRouter, Redirect} from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from './../store/actions/actions';

import Login from './../components/Login/Login';
import Signup from './../components/Login/Signup';
import ForgotPassword from './../components/Login/ForgotPassword';
import ResetPassword from './../components/Login/ResetPassword';
import IntroPrompt from './../components/IntroPrompt/IntroPrompt';

export const Auth = (props)=> {

	// MANAGE AUTH ROUTES
		// get auth status from localStorage
	const token = JSON.parse(localStorage.getItem('userAuth'));
		// get current prompt ?param=xxx
	const currentPath   = props.history.location.pathname;
	const currentPrompt = qs.parse(props.history.location.search).prompt;
		// get saved intro display settings
		// local - user opted-out
		// session - user already saw the intro in this session
	const introHide = (	localStorage.getItem('introHidePref') || sessionStorage.getItem('introHidePref') );
	
	// REAUTH
	const {authSubmitHandler} = props;
	React.useEffect(()=>{
		// if no auth-prompt and authData, but has saved token
		// start re-auth request 
		let savedToken = JSON.parse(localStorage.getItem('userAuth')); 	// re-establish to avoid repeat reauth
		if(!currentPrompt && savedToken){
			authSubmitHandler(savedToken.idToken, 'reauth', currentPath);
		}
	},[authSubmitHandler, currentPrompt, currentPath]);	// only run once

	// DYNAMIC ROUTES
	// display modal prompts
	// based on param
	const promptModal = ()=>{
		switch(currentPrompt){
			case 'login'				: return <Login />;
			case 'signup'				: return <Signup />;
			case 'resetpasswordlink'	: return <ForgotPassword />;
			case 'resetpassword'		: return <ResetPassword />;
			case 'signupintro'			: return <IntroPrompt />;
			// default - INTRO PROMPT
			// if not-authenticated and first-time session
			// display login intro prompt
			default: 
				// if user is...
				// first-time visit or new session 
				// display prompt - redirect to ?prompt-=signupintro
				return (!introHide && !token)
					?<Redirect to={currentPath+'?prompt=signupintro'} /> 
					:null;
		}
	};

	return (
		<Route render={ () => promptModal() } />
	);

};

const mapStateToProps = state => {
	return {
		authLoading: state.authReducer.authLoading,
		authError: state.authReducer.authError,
		authData: state.authReducer.authData,
		authRedirect: state.authReducer.authRedirect,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		authSubmitHandler: (values, loginType,redirectLink) => dispatch( actions.auth_submit(values,loginType,redirectLink) ),
		authResetHandler: (resetProp) => dispatch( actions.reset(resetProp) ),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));