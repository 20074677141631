import React from 'react';

const Input = (props)=> {
	return (
		<div className={`input-wrapper ${props.wrapperClass}`}>
			<label 	htmlFor={props.id}
					className={props.labelClass}
				>
				{props.label}
			</label>
			<input 	type={props.type} 
					id={props.id}  
					data-testid={props.testid} 
					name={props.name} 
					onChange={props.action}
					onBlur={props.blur}
					className={props.inputClass}
					placeholder={props.placeholder}
					value={props.value}
					defaultChecked={props.initialState}
				/>
			<span data-testid={`error-${props.id}`} className={`error-text ${props.errorText?'active':null}`}>{props.errorText}</span>
		</div>
	);
};

export default Input;