import React from 'react';

import { connect } from 'react-redux';
import * as actions from './../../store/actions/actions';

import classes from './Alert.module.css';
import {FaTimes} from 'react-icons/fa';


const Alert = (props)=> {

	const [display, setDisplay] = React.useState(true);

	React.useEffect(()=>{
		// cleanup - reset Alert after display
		if(props.reset){
			return ()=>{
				props.authResetHandler(props.reset);
			}
		}
	},[props]);
	
	return (
		<div	className={`${classes.Alert} ${props.type} ${props.classes}`} 
				style={ {display: `${ display ? 'block' : 'none' }`} }
			>
		  	<strong>{props.type}: </strong>
		  	{props.message}
		  	<FaTimes onClick={()=>setDisplay(false)} />
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		authResetHandler: (resetProp) => dispatch( actions.reset(resetProp)),
	}
}

export default connect(null, mapDispatchToProps)(Alert);