import React from 'react';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import Reducers from './store/reducers/Reducers';

import {BrowserRouter} from 'react-router-dom';
import Layout from './components/Layout/Layout';


const store = createStore(Reducers, applyMiddleware(thunk));

function App() {
	return (
		// <Root>
			<Provider store={store} >
				<div className="App">
					<BrowserRouter>
						<Layout />
					</BrowserRouter>
				</div>
			</Provider>
		// </Root>
	);
}

export default App;
