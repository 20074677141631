import React from 'react';

import classes from './ListRecipes.module.css';

const Pagination = (props)=> {
	const totalResults = props.totalResults;
	const perPage      = props.perPage;
	const pagiPage     = props.pagiPage; 
	
	// total pagiPages
	let totalPagiPages = Math.ceil(totalResults/perPage);	// so 42 or 48 will be 4 pages	
	// display totoal as current/total
	// is last pagilink, adjust total accordingly
	let currentTotal = (totalPagiPages === pagiPage) ?  totalResults : pagiPage*perPage ; 	
	let displayTotal   =  ((totalResults > perPage) ? currentTotal : totalResults )+'/'+totalResults;
    

    let pagiList = [];
    // generate pagilist
    // if available
    if( totalResults > perPage ){
	    for (let i = 1; i <= totalPagiPages; i++) {
	    	pagiList.push(
	    		<li key={i+'_'+totalResults} 
	    			className={ i === pagiPage ? 'active' : '' }
	    			onClick={()=>{
	    				props.pagiLinkHandler(i);
	    			}} 
	    			>{i}</li>
	    	);
	    }
    }

	return (
		<ul className={classes.Pagi}>
			{pagiList}
			<li key="totalResults" className="displayTotal">{displayTotal}</li>
		</ul>
	);
};

export default Pagination;