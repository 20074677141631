import React from 'react';

import classes from './QuickSearch.module.css';
import QuickSearchList from './QuickSearchList';
import Featured from './../Featured/Featured';

const QuickSearch = (props)=> {

	return (
		<div className={`${classes.Wrapper} ${props.parentClass}` } >
			<QuickSearchList />
			<div className={classes.Featured} >
				<Featured />
			</div>
		</div>
	);

};

export default QuickSearch;