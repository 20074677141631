import React from 'react';
import { FaCircleNotch } from 'react-icons/fa';

import classes from './Filters.module.css';
import Input from './../UI/Input';
import Slider from '@material-ui/core/Slider';

const FilterGroup = (props)=> { 
	const isRange = props.type === 'range';
	const isSingleOption = props.values.length === 1;  

	let groupItems=[];
	
	// range
	if(isRange){
		const marks = [
		  {
		    value: 0,
		    // label: '0',
		  },
		  {
		    value: 50,
		    // label: '50%',
		  },
		  {
		    value: 100,
		    // label: 'All',
		  },
		];
		groupItems.push(
			<Slider
				key={props.id}
				name={props.id}
		        defaultValue={props.values[2]}
		        step={props.values[3]}
		        valueLabelDisplay="auto"
		        marks={marks}
				onChange={(event, value) => props.onChangeHandler(value, props.id) }
		    />
		);
	}else{
		// regular inputs
		groupItems = props.values.map(val=>{
			let id = props.id+'_'+val
			return (
				<React.Fragment key={`${id}-loadicon`}>	
					<FaCircleNotch id={`${id}-loadicon`} className="filter-load-indicator fa-spinner" />
					<Input 	
						key={id}
						name={(props.type === "radio")? props.id : id }
						id={id}
						label={val}
						labelClass={`label-left ${classes.Label}`}
						value={val}
						type={props.type}
						inputClass={classes.Input}
						action={(event)=>props.onChangeHandler(event, val, props.id)}
					/>
				</React.Fragment>	
			);
		});
	}
	
	return (
		<fieldset 	className={`${classes.Group} ${isSingleOption && classes.SingleFilter}`}
					disabled={ props.resultLoading || !props.searchData }
				>
			{ !isSingleOption && <h3 className={classes.GroupTitle}>{props.label}</h3> }
			{ groupItems }
		</fieldset> 
	);
};

export default FilterGroup;