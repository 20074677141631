import React from 'react';
import MainMenuListItem from './MenuListItem';
import classes from './Menu.module.css';

const Comp = (props)=> {
	
	const menuItems = props.menuItems.map((menuItem, key)=>{
		let label = menuItem["label"];
		let action = (menuItem["action"]) ? menuItem["action"] : null;
		return  (
					<MainMenuListItem 	key={label} 
										link={menuItem["link"]}
										action={action}
									>
						<span className={`${classes.MenuItemIcon} menu-${props.menuType}`} >{menuItem["icon"]}</span>
						<span className={`${classes.MenuItemLabel} menu-${props.menuType}`} >{label}</span>
					</MainMenuListItem>
				);
	});

	return (
		<div className="menu" >
			{menuItems}
		</div>
	);
};

export default Comp;