import React from 'react';
import {useMousePosition} from './../../Hooks/Hooks';

const Tooltip = (props)=> {
	
	const {x, y} = useMousePosition();
	const refTooltip = React.useRef();
	
	// get wrapper top and left
	const wrapperElmPos = props.refWrapper.current ? props.refWrapper.current.getBoundingClientRect() : null;
	const tooltipElm    = refTooltip.current ? refTooltip.current.getBoundingClientRect() : null;
	
	return (
		<span 	className="tooltiptext"
				style={{ 
					top: (wrapperElmPos && tooltipElm) && y-wrapperElmPos.top-tooltipElm.height-40, 	// 20 gap for arrow icon
					left: (wrapperElmPos && tooltipElm) && x-wrapperElmPos.left-(tooltipElm.width/2), 	// 2 h-center 
					opacity: props.display ? 1 : 0
				}}
				ref={refTooltip}
			>
				{props.tooltipText}
		</span>
	);
};

export default Tooltip;